import { useGetMetricList } from '@/services/metric';
import PureMetricList, { IPureMetricListProps } from './PureMetricList';
import { ALL_METRIC_LENGTH } from '@/constants';
import { TMetric } from '@/typings/metric';
import { useGetCategoryList } from '@/services/category';
import { ECategoryType, ICategory } from '@/typings/category';
import { forwardRef } from 'react';
import { IMetricPickerRef } from './types';

export interface IProps
  extends Omit<IPureMetricListProps, 'metrics' | 'categories'> {
  metrics?: TMetric[] | null;
  categories?: ICategory[];
}

function MetricPicker(
  props: IProps,
  ref: React.ForwardedRef<IMetricPickerRef>,
) {
  const { metrics, loading: loadingFromProps, canFav, categories } = props;
  const { data: metricData, loading: isLoadingMetricData } = useGetMetricList(
    {
      pager: {
        pageSize: ALL_METRIC_LENGTH,
        pageNumber: 1,
      },
    },
    {
      skip: !!metrics,
      withFav: canFav,
    },
  );
  const { data: categoryData, loading: isLoadingCategoryData } =
    useGetCategoryList(
      { categoryType: ECategoryType.CATEGORY_METRIC },
      {
        skip: !!categories,
      },
    );

  // 如果外部传入了 metrics，则使用外部传入的，不然使用请求的
  // 在分析簿场景下，需要修改指标的收藏状态，所以需要用受控的指标列表
  const metricList = metrics || metricData;
  const categoryList = categories || categoryData;
  const isLoading =
    loadingFromProps || isLoadingMetricData || isLoadingCategoryData;

  return (
    <PureMetricList
      {...props}
      metrics={metricList || []}
      categories={categoryList || []}
      loading={isLoading}
      ref={ref}
    />
  );
}

export default forwardRef(MetricPicker);
