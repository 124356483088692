import { EColumnDataType } from '@/typings';
import { EFilterType, ELooseDataType, IBaseProps } from '../../../types';
import { Dropdown, MenuItemType, Select } from '@aloudata/aloudata-design';
import { looseDataTypeMap } from '../../../constants';
import { useMemo } from 'react';
import t from '@/locales';

export const numberEqualNotEqualOperatorMap = [
  {
    label: t.components.filter.operator.number.equal,
    value: EFilterType.NUMBER_EQUAL,
  },
  {
    label: t.components.filter.operator.number.notEqual,
    value: EFilterType.NUMBER_NOT_EQUAL,
  },
];

const operatorLooseDataTypeMap = {
  [ELooseDataType.TIME]: [
    {
      label: t.components.filter.operator.date.specific,
      value: EFilterType.DATE_SPECIFY,
    },
    {
      label: t.components.filter.operator.date.range,
      value: EFilterType.DATE_RANGE,
    },
    {
      label: t.components.filter.operator.date.outOfRange,
      value: EFilterType.DATE_OUTSIDE_RANGE,
    },
    {
      label: t.components.filter.operator.date.earlier,
      value: EFilterType.DATE_BEFORE,
    },
    {
      label: t.components.filter.operator.date.later,
      value: EFilterType.DATE_AFTER,
    },
    {
      label: t.components.filter.operator.common.rank.parent,
      value: EFilterType.RANK,
    },
    {
      label: t.components.filter.operator.common.isNull,
      value: EFilterType.IS_NULL,
    },
    {
      label: t.components.filter.operator.common.notNull,
      value: EFilterType.NOT_NULL,
    },
  ],
  [ELooseDataType.NUMBER]: [
    ...numberEqualNotEqualOperatorMap,
    {
      label: t.components.filter.operator.number.greater,
      value: EFilterType.GREATER_THAN,
    },
    {
      label: t.components.filter.operator.number.greaterEqual,
      value: EFilterType.GREATER_THAN_OR_EQUAL,
    },
    {
      label: t.components.filter.operator.number.less,
      value: EFilterType.LESS_THAN,
    },
    {
      label: t.components.filter.operator.number.lessEqual,
      value: EFilterType.LESS_THAN_OR_EQUAL,
    },
    {
      label: t.components.filter.operator.number.between,
      value: EFilterType.NUMBER_RANGE,
    },
    {
      label: t.components.filter.operator.number.outOfRange,
      value: EFilterType.NUMBER_OUTSIDE_RANGE,
    },
    {
      label: t.components.filter.operator.common.rank.parent,
      value: EFilterType.RANK,
    },
    {
      label: t.components.filter.operator.common.isNull,
      value: EFilterType.IS_NULL,
    },
    {
      label: t.components.filter.operator.common.notNull,
      value: EFilterType.NOT_NULL,
    },
  ],
  [ELooseDataType.TEXT]: [
    {
      label: t.components.filter.operator.text.equal,
      value: EFilterType.EQUAL,
    },
    {
      label: t.components.filter.operator.text.notEqual,
      value: EFilterType.NOT_EQUAL,
    },
    {
      label: t.components.filter.operator.text.include,
      value: EFilterType.INCLUDE,
    },
    {
      label: t.components.filter.operator.text.exclude,
      value: EFilterType.NOT_INCLUDE,
    },
    {
      label: t.components.filter.operator.text.startWith,
      value: EFilterType.START_WITH,
    },
    {
      label: t.components.filter.operator.text.notStartWith,
      value: EFilterType.NOT_START_WITH,
    },
    {
      label: t.components.filter.operator.text.endWith,
      value: EFilterType.END_WITH,
    },
    {
      label: t.components.filter.operator.text.notEndWith,
      value: EFilterType.NOT_END_WITH,
    },
    {
      label: t.components.filter.operator.common.rank.parent,
      value: EFilterType.RANK,
    },
    {
      label: t.components.filter.operator.common.isNull,
      value: EFilterType.IS_NULL,
    },
    {
      label: t.components.filter.operator.common.notNull,
      value: EFilterType.NOT_NULL,
    },
  ],
  [ELooseDataType.BOOLEAN]: [],
  [ELooseDataType.JSON]: [],
};

export const rankOperatorMap = [
  {
    label: t.components.filter.operator.common.rank.rank,
    value: EFilterType.RANK,
  },
  {
    label: t.components.filter.operator.common.rank.denseRank,
    value: EFilterType.RANK_DENSE,
  },
  {
    label: t.components.filter.operator.common.rank.rankRowNumber,
    value: EFilterType.ROW_NUMBER,
  },
];

/**
 * 操作符
 */
export default function Operator(props: IProps) {
  const { columnDataType, value, onChange, className } = props;

  const innerValue = useMemo(() => {
    if (
      value === EFilterType.RANK ||
      value === EFilterType.RANK_DENSE ||
      value === EFilterType.ROW_NUMBER
    ) {
      return (
        rankOperatorMap.find((item) => item.value === value)?.label || value
      );
    } else {
      return (
        operatorLooseDataTypeMap[looseDataTypeMap[columnDataType]].find(
          (item) => item.value === value,
        )?.label || value
      );
    }
  }, [columnDataType, value]);

  const looseDataType = looseDataTypeMap[columnDataType];

  const items = useMemo(() => {
    const menuItems: MenuItemType[] = [];

    let hasDivider = false;
    operatorLooseDataTypeMap[looseDataType].forEach((optItem) => {
      if (optItem.value === EFilterType.RANK) {
        menuItems.push({
          type: 'divider',
        });
        hasDivider = true;
        menuItems.push({
          label: optItem.label,
          key: optItem.value,
          children: rankOperatorMap.map((item) => ({
            label: item.label,
            key: item.value,
            onClick: () => onChange?.(item.value),
          })),
        });
      } else {
        if (optItem.value === EFilterType.IS_NULL && !hasDivider) {
          menuItems.push({
            type: 'divider',
          });
        }
        menuItems.push({
          key: optItem.value,
          label: optItem.label,
          onClick: () => onChange?.(optItem.value),
        });
      }
    });

    return menuItems;
  }, [looseDataType, onChange]);

  return (
    <Dropdown menu={{ items }}>
      <Select
        className={className}
        labelInValue
        value={innerValue}
        open={false}
      />
    </Dropdown>
  );
}

interface IProps extends IBaseProps {
  value?: EFilterType;
  onChange?: (value: EFilterType) => void;
  columnDataType: EColumnDataType;
}
