import {
  EMetricFilterType,
  IGetMetricListParams,
  IResourceVersionInfo,
  TDefineMetricDraft,
  IRowFilter,
  IStatisticsMetric,
} from '@/services/metric/types';
import { IListItems } from '@/typings';
import {
  useGqlLazyQuery,
  useGqlMutation,
  useGqlQuery,
} from '@/common/utils/gqlRequest';
import { gql } from '@apollo/client';
import { useCallback, useMemo } from 'react';
import _ from 'lodash';
import { originDataType2DataTypeMap } from '@/common/domain/Formula/constant';
import {
  EMetricOwnerType,
  EMetricType,
  EOnlineStatus,
  IMetricAttributionReportReq,
  IMetricAttributionTreeReq,
  IMetricPreviewConfigReq,
  TMetric,
} from '@/typings/metric';
import { TDefineMetric } from './types';
import {
  IAvailableDimensions,
  ISimpleDimension,
  TMetricCodeAvailableDimensionsMap,
} from '@/typings/dimension';
import {
  availableDimensionsTpl,
  metricBaseTpl,
  metricDetailTpl,
  metricSimpleTpl,
} from './gql';
import { ALL_METRIC_LENGTH } from '@/constants';
import {
  EResourceType,
  IAuthorityAccount,
  IAuthorityAccountResponseItem,
} from '@/typings/authority';
import { convertOldMetricToNew } from './helper';
import { getUniqId } from '@/common/utils';
import { get, post } from '@/common/utils/request';
import { TFormula } from '@/typings/formula';
import axios from 'axios';

export const QUERY_SIMPLE_METRIC_LIST = gql`
query queryMetricList($metricConditionRequest: MetricConditionRequest) {
      queryMetricList(metricConditionRequest: $metricConditionRequest) {
        data {
          ${metricSimpleTpl}
        }
        hasNext
        total
        pageNumber
        pageSize
      }
    }
  `;

export const QUERY_METRIC_LIST = gql`
    query queryMetricList($metricConditionRequest: MetricConditionRequest) {
      queryMetricList(metricConditionRequest: $metricConditionRequest) {
        data {
          ${metricBaseTpl}
        }
        hasNext
        total
        pageNumber
        pageSize
      }
    }
  `;

export function useGetMetricList(
  requestParams: IGetMetricListParams = {
    pager: {
      pageNumber: 1,
      pageSize: ALL_METRIC_LENGTH,
    },
  },
  opt?: {
    onCompleted?: (data: { queryMetricList: IListItems<TMetric> }) => void;
    skip?: boolean;
    /**
     * 是否查询收藏信息
     */
    withFav?: boolean;
    /**
     * 是否查询自定义属性
     */
    withProperties?: boolean;
    /**
     * 是否查询 owner 信息
     */
    withOwner?: boolean;
  },
) {
  const schema = QUERY_METRIC_LIST;
  const { data, loading, refetch } = useGqlQuery<
    {
      queryMetricList: IListItems<TMetric>;
    },
    {
      metricConditionRequest: {
        keyword?: string; // 关键词
        categoryId?: string; // 指标code
        types?: EMetricType[]; // 指标类型
        currentPage?: number; // 指定页码
        owners?: string[]; // 负责人
        useable?: boolean; // 我可用的
        authority?: EMetricFilterType;
        pager?: {
          pageSize: number;
          pageNumber: number;
        };
      };
    }
  >(
    schema,
    {
      metricConditionRequest: requestParams,
    },
    {
      onCompleted: opt?.onCompleted,
      skip: opt?.skip,
    },
  );

  function parseDataType(list?: TMetric[]): TMetric[] | null {
    if (!list) {
      return null;
    }
    return _.map(list, (item: TMetric) => {
      return convertOldMetricToNew({
        ..._.cloneDeep(item),
        dataType: originDataType2DataTypeMap[item.originDataType],
      })!;
    });
  }

  const listWithDataType = useMemo(() => {
    return parseDataType(data?.queryMetricList.data);
  }, [data]);

  const queryLazy = useCallback(
    (params: IGetMetricListParams) => {
      return refetch({
        metricConditionRequest: params,
      }).then((res) => parseDataType(res?.data?.queryMetricList.data)!);
    },
    [refetch],
  );

  return {
    data: listWithDataType,
    total: data?.queryMetricList.total || 0,
    loading,
    refetch: queryLazy,
  };
}

export function useGetMetricListLazy(useSimpleMetric = false) {
  const schema = useSimpleMetric ? QUERY_METRIC_LIST : QUERY_SIMPLE_METRIC_LIST;
  const { data, loading, run } = useGqlLazyQuery<
    {
      queryMetricList: IListItems<TMetric>;
    },
    {
      metricConditionRequest: {
        keyword?: string; // 搜索
        categoryId?: string; // 指标code
        types?: EMetricType[]; // 指标类型
        currentPage?: number; // 指定页码
        owners?: string[]; // 负责人
        useable?: boolean; // 我可用的
        authority?: EMetricFilterType;
        pager?: {
          pageSize: number;
          pageNumber: number;
        };
      };
    }
  >(schema, {});

  function parseDataType(list?: TMetric[]): TMetric[] {
    if (!list) {
      return [];
    }
    return _.map(list, (item: TMetric) => {
      return {
        ...convertOldMetricToNew(item)!,
        dataType: originDataType2DataTypeMap[item.originDataType],
      };
    });
  }

  const listWithDataType = useMemo(() => {
    return parseDataType(data?.queryMetricList.data);
  }, [data]);
  return {
    data: listWithDataType,
    total: data?.queryMetricList.total || 0,
    loading,
    run: useCallback(
      async (req: {
        metricConditionRequest: {
          //按名称搜索
          name?: string; // 指标名称
          categoryId?: string; // 指标code
          types?: EMetricType[]; // 指标类型
          currentPage?: number; // 指定页码
          owners?: string[]; // 负责人
          useable?: boolean; // 我可用的
          authority?: EMetricFilterType;
          pager?: {
            pageSize: number;
            pageNumber: number;
          };
        };
      }) => {
        const res = await run(req);
        return {
          queryMetricList: {
            ...res.queryMetricList,
            data: parseDataType(res.queryMetricList.data),
          },
        };
      },
      [run],
    ),
  };
}

export const QUERY_METRIC_DETAIL = gql`query queryMetricDetail($code: String,$version: Int) {
    queryMetricDetail(code: $code,version:$version) {
      ${metricDetailTpl}
    }
  }`;
/**
 * 手动获取指标详情
 */
export function useGetMetricInfoLazy() {
  const schema = QUERY_METRIC_DETAIL;
  const { data, loading, run } = useGqlLazyQuery<
    { queryMetricDetail: TMetric },
    { code: string; version?: number | null }
  >(schema);

  const getMetricWithDataType = useCallback((metric: TMetric): TMetric => {
    return {
      ...convertOldMetricToNew(metric)!,
      dataType: originDataType2DataTypeMap[metric.originDataType],
    };
  }, []);

  return {
    data: data?.queryMetricDetail
      ? getMetricWithDataType(data?.queryMetricDetail)
      : null,
    loading,
    run: useCallback(
      (req: { code: string; version?: number | null }) => {
        return run({
          code: req.code,
          version: req.version ?? null,
        }).then((res) => {
          return {
            queryMetricDetail: getMetricWithDataType(res.queryMetricDetail),
          };
        });
      },
      [getMetricWithDataType, run],
    ),
  };
}

/**
 * 删除指标
 */
export function useDeleteMetric() {
  const schema = gql`
    mutation deleteMetric($code: String) {
      deleteMetric(code: $code)
    }
  `;
  const { run: deleteMetric, loading } = useGqlMutation<
    boolean,
    {
      code: string;
    }
  >(schema);

  return {
    deleteMetric,
    loading,
  };
}

/**
 * 创建指标
 */
export function useCreateMetric() {
  const schema = gql`
    mutation createMetric($metricSaveRequest: MetricSaveRequest) {
      createMetric(metricSaveRequest: $metricSaveRequest)
    }
  `;
  const { run: createMetric, loading } = useGqlMutation<
    {
      createMetric: string;
    },
    {
      metricSaveRequest: TDefineMetric | TDefineMetricDraft;
    }
  >(schema);

  return {
    createMetric,
    loading,
  };
}

/**
 * 更新指标
 */
export function useUpdateMetric() {
  const schema = gql`
    mutation updateMetric($metricSaveRequest: MetricSaveRequest) {
      updateMetric(metricSaveRequest: $metricSaveRequest)
    }
  `;
  const { run: updateMetric, loading } = useGqlMutation<
    {
      updateMetric: string;
    },
    {
      metricSaveRequest: TDefineMetric | TDefineMetricDraft;
    }
  >(schema);

  return {
    updateMetric,
    loading,
  };
}
/**
 * 校验指标定义是否重复
 */
export function useCheckMetric() {
  const schema = gql`
    query definitionDuplicatedCheck($metricDefinition: MetricSaveRequest) {
      definitionDuplicatedCheck(metricDefinition: $metricDefinition) {
        code
        name
        metricName
        metricCode
        description
      }
    }
  `;
  const { run: definitionDuplicatedCheck, loading } = useGqlLazyQuery<
    {
      definitionDuplicatedCheck: TMetric[];
    },
    {
      metricDefinition: TDefineMetric;
    }
  >(schema);

  return {
    definitionDuplicatedCheck,
    loading,
  };
}

/**
 * 收藏指标
 */
export function useCollectMetric() {
  const schema = gql`
    mutation collectMetric($code: String) {
      collectMetric(code: $code)
    }
  `;
  const { run: collectMetric } = useGqlMutation<
    boolean,
    {
      code: string;
    }
  >(schema);

  return {
    collectMetric,
  };
}

/**
 * 取消收藏指标
 */
export function useCancelCollectMetric() {
  const schema = gql`
    mutation cancelCollectMetric($code: String) {
      cancelCollectMetric(code: $code)
    }
  `;
  const { run: cancelCollectMetric } = useGqlMutation<
    boolean,
    {
      code: string;
    }
  >(schema);

  return {
    cancelCollectMetric,
  };
}

/**
 * 编辑指标基础信息
 */
export function useEditMetricBaseInfo() {
  const schema = gql`
    mutation editMetric($metricBaseInfoRequest: MetricBaseInfoRequest) {
      editMetric(metricBaseInfoRequest: $metricBaseInfoRequest)
    }
  `;
  const { run: editMetric, loading } = useGqlMutation<
    boolean,
    {
      metricBaseInfoRequest: {
        code: string;
        name: string;
        description: string;
        categoryId: string;
        owners: Array<{
          type: EMetricOwnerType;
          userId: string;
        }>;
      };
    }
  >(schema);

  return {
    editMetric,
    loading,
  };
}

export interface IMetricListAvailableDimensionsResult {
  queryMetricList: {
    data: Array<{
      code: string;
      availableDimensions: ISimpleDimension[];
    }>;
  };
}

export function getAvailableDimensionsTpl(withOwner = false) {
  return gql`
    query queryMetricList($metricConditionRequest: MetricConditionRequest) {
      queryMetricList(metricConditionRequest: $metricConditionRequest) {
        data {
          code
          availableDimensions {
            ${availableDimensionsTpl}
            ${
              withOwner
                ? `owner {
              userId
              nickname
              account
            }`
                : ''
            }
          }
        }
      }
    }
  `;
}
/**
 * 根据一到多个指标获取 维度
 * 这是个工具方法，用在编辑指标定义时，根据编辑中的定义动态查询可用维度
 */
export function useGetDimensionListByMetricCodes(withOwner = false) {
  const schema = getAvailableDimensionsTpl(withOwner);
  const DIMENSION_NUMBER = 1000;
  const {
    data,
    loading,
    run: refetch,
  } = useGqlLazyQuery<
    IMetricListAvailableDimensionsResult,
    {
      metricConditionRequest: {
        codes: string[];
        dimensionAuthFilter: false;
        pager: {
          pageSize: number; //目的是一页请求所有维度
          pageNumber: 1;
        };
      };
    }
  >(schema, {});

  const getDimensionListWithDataType = useCallback(
    (result?: IMetricListAvailableDimensionsResult, manual = false) => {
      // 取查询的多个指标的维度的交集
      const list = result?.queryMetricList.data;
      if (!list) {
        return null;
      }

      const allAvailableDimensions = _.intersectionBy(
        ...list.map((item) => {
          if (manual) {
            return item.availableDimensions.filter((dim) => dim.isEnable);
          } else {
            return item.availableDimensions;
          }
        }),
        'name',
      );
      return allAvailableDimensions;
    },
    [],
  );
  const listData = getDimensionListWithDataType(data);
  return {
    data: listData,
    manualAvailableDimensions: listData
      ? listData.filter((item) => item.isEnable)
      : [],
    loading,
    run: useCallback(
      (codeArray: string[]) => {
        return refetch({
          metricConditionRequest: {
            codes: codeArray,
            dimensionAuthFilter: false,
            pager: {
              pageSize: DIMENSION_NUMBER,
              pageNumber: 1,
            },
          },
        });
      },
      [refetch],
    ),
    getDimensionListWithDataType,
  };
}

export const QUERY_DIMENSIONS_BY_DATASET_NAME = gql`
  query queryDimensionsByDatasetName(
    $datasetName: String
    $editMetricCode: String
  ) {
    queryDimensionsByDatasetName(
      datasetName: $datasetName
      editMetricCode: $editMetricCode
    ) {
      name
      dimName
      displayName
      description
      dataType
      hasPermission
      originDataType
      isPublished
      category {
        id
        name
      }
    }
  }
`;

/**
 * 获取 指标 维度列表
 */
export function useGetDimensionListByDatasetName() {
  const { data, loading, run } = useGqlLazyQuery<
    {
      queryDimensionsByDatasetName: ISimpleDimension[];
    },
    {
      datasetName: string;
      editMetricCode?: string;
    }
  >(QUERY_DIMENSIONS_BY_DATASET_NAME, {});
  const listWithDataType = useMemo(() => {
    const list = data?.queryDimensionsByDatasetName;
    if (!list) {
      return null;
    }
    return list;
  }, [data]);

  return {
    data: listWithDataType,
    loading,
    run,
  };
}

/**
 * 保存指标预览的配置信息
 */
export function useSaveMetricPreviewConfig() {
  const schema = gql`
    mutation saveMetricPreviewConfig(
      $metricPreviewConfig: MetricPreviewConfigRequest
    ) {
      saveMetricPreviewConfig(metricPreviewConfig: $metricPreviewConfig)
    }
  `;
  return useGqlMutation<
    boolean,
    {
      metricPreviewConfig: IMetricPreviewConfigReq;
    }
  >(schema);
}

/**
 * 保存指标归因树的配置信息
 */
export function useSaveMetricAttributionTree() {
  const schema = gql`
    mutation saveMetricAttributionTree(
      $metricAttributionTree: MetricPreviewConfigRequest
    ) {
      saveMetricAttributionTree(metricAttributionTree: $metricAttributionTree)
    }
  `;
  return useGqlMutation<
    boolean,
    {
      metricAttributionTree: IMetricAttributionTreeReq;
    }
  >(schema);
}

/**
 * 保存指标归因报告的配置信息
 */
export function useSaveMetricAttributionReport() {
  const schema = gql`
    mutation saveMetricAttributionReport(
      $metricAttributionReport: MetricPreviewConfigRequest
    ) {
      saveMetricAttributionReport(
        metricAttributionReport: $metricAttributionReport
      )
    }
  `;
  return useGqlMutation<
    boolean,
    {
      metricAttributionReport: IMetricAttributionReportReq;
    }
  >(schema);
}

/**
 * 获取用于统计的指标列表，每项只包含少量属性
 */
export function useGetMetricListForStatistics() {
  const schema = gql`
    query queryMetricList($metricConditionRequest: MetricConditionRequest) {
      queryMetricList(metricConditionRequest: $metricConditionRequest) {
        data {
          type
          category {
            id
            name
          }
          isPublished
          status
        }
        hasNext
        total
        pageNumber
        pageSize
      }
    }
  `;
  return useGqlQuery<
    {
      queryMetricList: IListItems<IStatisticsMetric>;
    },
    {
      metricConditionRequest: {
        pager?: {
          pageSize: number;
          pageNumber: number;
        };
      };
    }
  >(schema, {
    metricConditionRequest: {
      pager: {
        pageSize: ALL_METRIC_LENGTH,
        pageNumber: 1,
      },
    },
  });
}

// 指标发布
export function usePublishResource() {
  const schema = gql`
    mutation publishResource(
      $resourceId: String
      $resourceType: String
      $version: Int
    ) {
      publishResource(
        resourceId: $resourceId
        resourceType: $resourceType
        version: $version
      )
    }
  `;
  const { run: publishResource, loading } = useGqlMutation<
    boolean,
    {
      resourceType: string;
      resourceId: string;
      version: number;
    }
  >(schema);

  return {
    publishResource,
    loading,
  };
}

// 上线/下线指标
export function useUpdateResourceStatus() {
  const schema = gql`
    mutation updateResourceStatus(
      $resourceId: String
      $resourceType: String
      $status: ResourceStatusEnum
    ) {
      updateResourceStatus(
        resourceId: $resourceId
        resourceType: $resourceType
        status: $status
      )
    }
  `;
  const { run: updateResourceStatus, loading } = useGqlMutation<
    boolean,
    {
      resourceId: string;
      resourceType: EResourceType;
      status: EOnlineStatus;
    }
  >(schema);

  return {
    updateResourceStatus,
    loading,
  };
}

// 删除某个版本的指标
export function useDeleteResourceVersion() {
  const schema = gql`
    mutation deleteResourceVersion(
      $resourceId: String
      $resourceType: String
      $version: Int
    ) {
      deleteResourceVersion(
        resourceId: $resourceId
        resourceType: $resourceType
        version: $version
      )
    }
  `;
  const { run: deleteResourceVersion, loading } = useGqlMutation<
    boolean,
    {
      resourceId: string;
      resourceType: EResourceType;
      version: number;
    }
  >(schema);

  return {
    deleteResourceVersion,
    loading,
  };
}

// 查询指标版本列表
export function useQueryResourceVersionList() {
  const schema = gql`
    query queryResourceVersionList($resourceId: String, $resourceType: String) {
      queryResourceVersionList(
        resourceId: $resourceId
        resourceType: $resourceType
      ) {
        resourceId
        resourceType
        version
        updater {
          userId
          nickname
          account
          photo
        }
        gmtCreate
        status
      }
    }
  `;
  const { loading, run } = useGqlLazyQuery<
    {
      queryResourceVersionList: Array<IResourceVersionInfo>;
    },
    {
      resourceId: string;
      resourceType: EResourceType;
    }
  >(schema);

  return {
    loading,
    queryResourceVersionList: run,
  };
}

//保存行级权限规则
export const useUpdateRowFilterRule = () => {
  const schema = gql`
    mutation updateRowFilterRules($rowFilterRuleRequest: RowFilterRuleRequest) {
      updateRowFilterRules(rowFilterRuleRequest: $rowFilterRuleRequest)
    }
  `;

  const { run: updateRowFilterRule, loading } = useGqlMutation<
    {
      updateRowFilterRule: Boolean;
    },
    {
      rowFilterRuleRequest: IRowFilter<IAuthorityAccount>;
    }
  >(schema);

  return {
    updateRowFilterRule,
    loading,
  };
};

// 查询规则列表
export const useQueryRowFilterRuleList = () => {
  const schema = gql`
    query queryRowFilterRuleList($resourceType: String, $resourceId: String) {
      queryRowFilterRuleList(
        resourceType: $resourceType
        resourceId: $resourceId
      ) {
        resourceId
        resourceType
        ruleType
        rules {
          id
          name
          ruleType
          layout
          rule {
            ... on ConditionRuleFilterInfo {
              ruleContents
              users {
                accountType
                account
                id
                displayName
              }
              whitelists {
                accountType
                account
                id
                displayName
              }

              dimensionFilter {
                dimensionFlag
                includes
                excludes
              }
            }
            ... on TableRuleFilter {
              table
              userIdentifier
              dimensionFilterFlag
              logicType
              tableRuleContents {
                name
                value
                dimensionFilter {
                  dimensionFlag
                  includes
                  excludes
                }
              }
            }
          }
        }
      }
    }
  `;

  const { run, loading } = useGqlLazyQuery<
    { queryRowFilterRuleList: IRowFilter<IAuthorityAccountResponseItem> },
    {
      resourceType: EResourceType;
      resourceId: string;
    }
  >(schema);

  return {
    queryRowFilerRuleList: (
      resourceType: EResourceType,
      resourceId: string,
    ) => {
      return run({
        resourceType,
        resourceId,
      }).then((res) => {
        return {
          ...res.queryRowFilterRuleList,
          rules:
            res.queryRowFilterRuleList?.rules?.map((item) => ({
              tempId: getUniqId(),
              ...item,
            })) || [],
        };
      });
    },
    loading,
  };
};
/**
 * 校验指标名称是否唯一
 */
export function checkMetricNameExists(name: String, code?: String) {
  return get<{
    result: boolean;
    errorMsg: string;
  }>('/metric/name_duplicated_check', {
    metricName: name,
    code,
  });
}
/**
 * 校验指标编码是否唯一
 */
export function checkMetricCodeExists(metricCode: String, code?: String) {
  return get<{
    result: boolean;
    errorMsg: string;
  }>('/metric/code_duplicated_check', {
    metricCode,
    code,
  });
}
/**
 * 校验指标展示名是否唯一
 */
export function checkMetricDisplayNameExists(
  name: String,
  code?: String,
): Promise<boolean> {
  return get('/metric/display_name_duplicated_check', {
    metricDisplayName: name,
    code,
  });
}

/**
 * 校验指标自定义表达式是否正确
 * @param sql SQL 代码
 * @param datasourceItem 数据源
 */
export function validateMetricExpr(
  formula: TFormula,
  metricType: EMetricType,
): Promise<{
  success: boolean;
  errorMsg: string;
}> {
  return post('/metric/expr_validate', {
    formula,
    // 默认返回一百条数据
    metricType,
  });
}

/**
 * 获取全量可用维度或者根据指标一个或者多个code获取指定指标的可用维度
 */

export const useGetAvailableDimensions = (
  withOwner = false,
  filterByColumnPermission = true,
) => {
  const schema = getAvailableDimensionsTpl(withOwner);
  const { loading, run } = useGqlLazyQuery<
    {
      queryMetricList: IListItems<IAvailableDimensions>;
    },
    {
      metricConditionRequest: {
        codes?: string[];
        filterByColumnPermission: boolean;
        pager: {
          pageSize: number; //目的是一页请求所有维度
          pageNumber: 1;
        };
      };
    }
  >(schema);

  function convert2Map(list: IAvailableDimensions[]) {
    const initialValue: TMetricCodeAvailableDimensionsMap = {};
    return _.reduce(
      list,
      (acc, item) => {
        const { code, availableDimensions } = item;
        acc[code] = availableDimensions;
        return acc;
      },
      initialValue,
    );
  }

  const query = useCallback(
    (codes?: string[]) => {
      return run({
        metricConditionRequest: {
          codes,
          filterByColumnPermission,
          pager: {
            pageSize: ALL_METRIC_LENGTH,
            pageNumber: 1,
          },
        },
      }).then((res) => {
        return convert2Map(res.queryMetricList.data || []);
      });
    },
    [run, filterByColumnPermission],
  );

  return {
    loading,
    run: query,
  };
};
/**
 * 下载上传指标模板
 */
export function downloadTemplateOfUploadMetric() {
  return axios('/metric/template/download', {
    method: 'GET',
    responseType: 'blob',
    headers: {
      'Content-Type': 'application/json',
    },
    data: {},
  });
}

/**
 *  上传指标模板
 * @param file 指标模板文件
 */
export function uploadMetric(file: File): Promise<boolean> {
  return post('metric/template/upload', {
    file,
  });
}

/**
 *  批量创建指标草稿
 */

export function useBatchCreateMetrics() {
  const schema = gql`
    mutation batchCreateDraft($uploadId: String) {
      batchCreateDraft(uploadId: $uploadId)
    }
  `;

  return useGqlMutation<
    {
      batchCreateDraft: Boolean;
    },
    {
      uploadId: string;
    }
  >(schema);
}

export function useQueryMetricMatchesCandidateMetrics() {
  const schema = gql`
    query queryMetricMatchesCandidate($metricCode: String, $dimensionName: String) {
      queryMetricMatchesCandidate(metricCode: $metricCode,dimensionName:$dimensionName) {
        metrics {
          ${metricBaseTpl}
        }
        disabledMetrics {
          code
          message
        }

      }
    }
  `;

  return useGqlLazyQuery<
    {
      queryMetricMatchesCandidate: {
        metrics: TMetric[];
        disabledMetrics: Array<{ code: string; message: string }>;
      };
    },
    {
      metricCode?: string;
      dimensionName: string;
    }
  >(schema);
}

/**
 * 首页需要获取带口径的指标列表
 */
export function useQueryMetricListWithCaliber(
  requestParams: IGetMetricListParams = {
    pager: {
      pageNumber: 1,
      pageSize: ALL_METRIC_LENGTH,
    },
  },
) {
  const schema = gql`
    query queryMetricList($metricConditionRequest: MetricConditionRequest) {
      queryMetricList(metricConditionRequest: $metricConditionRequest) {
        data {
          ${metricSimpleTpl}
          caliber
        }
        hasNext
        total
        pageNumber
        pageSize
      }
    }
  `;
  return useGqlQuery<
    {
      queryMetricList: IListItems<TMetric>;
    },
    {
      metricConditionRequest: {
        keyword?: string; // 关键词
        categoryId?: string; // 指标code
        types?: EMetricType[]; // 指标类型
        currentPage?: number; // 指定页码
        owners?: string[]; // 负责人
        useable?: boolean; // 我可用的
        authority?: EMetricFilterType;
        pager?: {
          pageSize: number;
          pageNumber: number;
        };
      };
    }
  >(schema, {
    metricConditionRequest: requestParams,
  });
}

export interface ITransferRequest {
  resources: string[]; // 资源列表
  resourceType: string; // 资源类型：DATASOURCE/DATASET。。。。
  targetOwner: string; // 转交对象的userId
  currentOwner?: string; // 当前负责人, 单个转交时需要，批量时会根据资源列表去获取负责人
}
export function useTransferResources() {
  const schema = gql`
    mutation transferResources($transferRequest: TransferRequest) {
      transferResources(transferRequest: $transferRequest)
    }
  `;

  return useGqlMutation<
    {
      transferResources: boolean;
    },
    {
      transferRequest: ITransferRequest;
    }
  >(schema);
}
