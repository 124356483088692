import { getUniqId } from '../../src/common/utils';
import { getMockUserBindRoleList, mockUserAuthorityList } from './mockData';
import _ from 'lodash';
import { graphql, rest } from 'msw';
import { getRestfulResponse } from '../utils';
import isPossibilityTrue from '../utils/isPossibilityTrue';
import { EResourceType, EUserRole } from '../../src/typings/authority';

export const authority = [
  // 获取用户权限
  rest.post('/preQuery', (req, res, ctx) => {
    return res(
      ctx.json(
        getRestfulResponse({
          id: getUniqId(),
          type: 'DATASET',
          hasAuthority: true,
          isExist: true,
        }),
      ),
    );
  }),
  // 分享
  graphql.mutation('grantAuth', (req, res, ctx) => {
    return res(
      ctx.data({
        grantAuth: true,
      }),
    );
  }),
  //权限
  graphql.mutation('grant', (req, res, ctx) => {
    return res(
      ctx.data({
        grant: true,
      }),
    );
  }),
  // 撤权
  graphql.mutation('revoke', (req, res, ctx) => {
    return res(
      ctx.data({
        revoke: true,
      }),
    );
  }),
  // 根据资源获取权限列表
  graphql.query('queryUserAuthorityList', (req, res, ctx) => {
    return res(
      ctx.data({
        queryUserAuthorityList: mockUserAuthorityList,
      }),
      ctx.delay(1000),
    );
  }),
  // 根据用户获取权限列表
  graphql.query('queryDirectAuthResourcesByResourceType', (req, res, ctx) => {
    return res(
      ctx.data({
        queryDirectAuthResourcesByResourceType: [],
      }),
      ctx.delay(1000),
    );
  }),
  // 更改权限
  graphql.mutation('updateAuthorityRole', (req, res, ctx) => {
    return res(
      ctx.data({
        updateAuthorityRole: true,
      }),
    );
  }),
  // 查询是否有创建权限
  graphql.query('authenticateCreate', (req, res, ctx) => {
    return res(
      ctx.data({
        authenticateCreate: isPossibilityTrue(99),
      }),
    );
  }),
  // 获取资源最大权限
  graphql.query('queryUserMaxAuthorityRole', (req, res, ctx) => {
    return res(
      ctx.data({
        queryUserMaxAuthorityRole: {
          authoritySource: 'DIRECT',
          authorityRole: 'OWNER',
          authority: {
            canCreate: true,
            canEdit: true,
            canDelete: true,
            canUsage: true,
            canAuth: true,
            canMove: true,
            authRoleList: ['ADMIN', 'USAGER'],
          },
        },
      }),
    );
  }),
  // 获取资源类型的角色
  graphql.query('queryGrantRoleList', (req, res, ctx) => {
    return res(
      ctx.data({
        queryGrantRoleList: [
          {
            resourceType: EResourceType.ANALYSIS_VIEW,
            authRoleList: [EUserRole.ADMIN, EUserRole.USAGER],
          },
          {
            resourceType: EResourceType.DATASET,
            authRoleList: [EUserRole.ADMIN, EUserRole.USAGER],
          },
          {
            resourceType: EResourceType.METRIC,
            authRoleList: [EUserRole.ADMIN, EUserRole.USAGER],
          },
          {
            resourceType: EResourceType.DIMENSION,
            authRoleList: [EUserRole.ADMIN, EUserRole.USAGER],
          },
          {
            resourceType: EResourceType.DATASOURCE,
            authRoleList: [EUserRole.ADMIN, EUserRole.USAGER],
          },
        ],
      }),
    );
  }),
  graphql.query('querySpecifyDataTypeProperty', (req, res, ctx) => {
    return res(
      ctx.data({
        querySpecifyDataTypeProperty: getMockUserBindRoleList(
          req.variables.type,
        ),
      }),
    );
  }),
  graphql.mutation('saveUserTypePropertyBindRole', (req, res, ctx) => {
    return res(
      ctx.data({
        saveUserTypePropertyBindRole: true,
      }),
      ctx.delay(500),
    );
  }),
  rest.post('/config/module_switch', (req, res, ctx) => {
    return res(
      ctx.json(
        getRestfulResponse({
          disableAcceleration: false,
        }),
      ),
    );
  }),
  graphql.query('queryResourceViewerDetail', (req, res, ctx) => {
    return res(
      ctx.data({
        queryResourceViewerDetail: {
          viewerList: [
            {
              accountType: 'USER',
              id: '1',
              displayName: 'test',
            },
            {
              accountType: 'USER',
              id: '2',
              displayName: 'test2',
            },
          ],
          allViewer: _.random(0, 1) === 1,
          canEdit: true,
        },
      }),
    );
  }),
  graphql.mutation('grantViewAuthority', (req, res, ctx) => {
    return res(
      ctx.data({
        grantViewAuthority: true,
      }),
      ctx.delay(1000),
    );
  }),
  graphql.query('queryRoleList', (req, res, ctx) => {
    return res(
      ctx.data({
        queryRoleList: [
          {
            resourceType: EResourceType.METRIC,
            authRoleList: [EUserRole.ADMIN, EUserRole.USAGER],
          },
        ],
      }),
    );
  }),
  graphql.query('queryRowAuthRuleList', (req, res, ctx) => {
    return res(
      ctx.data({
        queryRowAuthRuleList: [
          {
            id: getUniqId(),
            name: 'test',
            description: 'test',
            creator: {
              id: getUniqId(),
              name: 'test',
            },
            metricInfluence: [{ influence: 'ALL' }],
            userInfluence: [{ influence: 'ALL' }],
            ruleConfig: {
              id: getUniqId(),
            },
            layout: 'test',
          },
        ],
      }),
    );
  }),
  graphql.mutation('deleteRowAuthRule', (req, res, ctx) => {
    return res(
      ctx.data({
        deleteRowAuthRule: true,
      }),
    );
  }),
];
