export const analysis = {
  list: {
    title: {
      page: '全部指标视图',
    },
    displayName: '展示名',
    name: '名称',
    description: '描述',
    updateTime: '最后编辑',
    handler: '操作',
    owner: '负责人',
    create: '新建指标视图',
    menu: {
      edit: '编辑',
      transfer: '转交',
      copy: '复制',
    },
    batch: {
      transfer: (value: string) => `指标视图 ${value} 无转交权限`,
      adjustCategory: (value: string) => `指标视图 ${value} 无调整类目权限`,
    },
  },
  config: {
    metric: {
      noquick: '无',
      periodicComparison: {
        default: '同环比',
      },
      ranking: {
        default: '排名',
        globalAsc: '全局升序',
        globalDesc: '全局降序',
        groupAsc: '组内升序',
        groupDesc: '组内降序',
        groupTitle: '选择排名范围',
      },
      proportion: {
        default: '占比',
        global: '全局占比',
        group: '组内占比',
        groupTitle: '选择占比范围',
      },
      dateLimit: {
        none: '无',
        custom: '自定义',
        modalTile: '自定义时间限定',
      },
    },
  },
};
export const apiIntegrated = {
  title: 'API 集成',
  desc: {
    desc1:
      '指标平台面向开发者提供了详尽的 API 服务接口，主要包含访问凭据管理、指标查询、指标多维归因、维度管理、指标管理、指标/维度类目管理、指标视图管理等。',
    desc2:
      '当访问 API 时，需要使用到 API 的环境地址、租户ID（tenant-id） 和 用户ID（user-id）信息。',
  },
  env: '环境信息',
  tip: {
    tip1: '你可以访问',
    detail: '指标平台使用手册',
    tip2: '，来查阅详细的 API 使用说明',
  },
  copy: '复制',
  copySuccess: '复制成功',
};
export const analysisView = {
  sideBar: {
    title: '指标视图',
    documentation: 'BI 工具集成说明',
  },
  overview: {
    title: '数据预览',
    noPermission: '无权查看，请向管理员申请权限',
  },
  list: {
    menu: {
      transfer: '转交',
      editBaseInfo: '修改基础信息',
      delete: '删除',
    },
    total: (total: number) => {
      return `${total} 个指标视图`;
    },
    empty: '没找到任何内容，快来创建指标视图吧',
    create: '新建指标视图',
    deleteSuccess: '删除成功',
  },
  deleteSuccess: '删除成功',
  detail: {
    share: '分享',
    authority: {
      default: '权限',
    },
    edit: '编辑',
    editBaseInfo: '编辑基础信息',
    lineage: '血缘',
    overview: '概览',
    column: {
      default: '字段',
      displayName: '展示名',
      name: '名称',
      datatype: '数据类型',
      description: '描述',
      emptyDesc: '没有符合搜索条件的字段，请重新尝试搜索',
      columnCount: ({ count }: { count: string | number }) => `${count} 个字段`,
    },
    basicInfo: {
      title: '基础信息',
      name: '名称',
      category: '类目',
      description: '描述',
      owner: '负责人',
      createTime: '创建时间',
      updateTime: '最新修改时间',
    },
    editBaeInfoSuccess: '修改基础信息成功',
  },
  title: {
    unnamed: 'NewView',
    unDisplayNamed: '未命名指标视图',
  },
  picker: {
    metric: {
      default: '指标',
      onlyViewPermission: '只看有权限',
      unusable: ({ list }: { list: string | number }) =>
        `该指标无法使用维度${list}筛选`,
    },
    dimension: {
      default: '维度',
      unusable: ({ list }: { list: string | number }) =>
        `该维度无法分析指标 ${list}`,
    },
  },
  result: {
    error: '获取指标视图信息异常',
  },
  basicInfo: {
    title: '发布',
    name: '名称',
    displayName: '展示名',
    category: '类目',
    description: '描述',
    confirm: '保存',
    cancel: '取消',
    error: {
      name: '只能输入英文字母，数字和下划线，且不能为空',
    },
    empty: {
      category: '请选择类目',
      displayName: '请输入展示名',
    },
  },
  header: {
    btn: {
      cancel: '取消',
      edit: '编辑',
      share: '分享',
      save: '保存',
      saveSuccess: '保存成功',
    },
  },
  area: {
    header: {
      data: '数据',
      config: '分析配置',
    },
  },
  chart: {
    config: {
      column: {
        title: '维度',
      },
      value: {
        title: '指标',
      },
    },
    select: {
      placeholder: '拖入或选择分析的维度',
    },
    value: {
      placeholder: '拖入或选择分析的指标',
    },
  },
  config: {
    metricDataRange: '指标日期范围',
    column: {
      overflow: ({ maxLength }: { maxLength: string | number }) =>
        `超出最大数量限制，最大数量为${maxLength}`,
    },
  },
  menu: {
    title: {
      filter: '业务限定',
      rename: '重命名',
      delete: '删除',
      view: '查看',
      share: '分享',
      granularity: '日期粒度',
      derivativeCalculation: '快速衍生',
      dateLimit: '时间限定',
    },
    filter: {
      desc: '满足以下条件',
      clean: '清空',
      add: '添加条件',
      error: '请填写完整条件选择器',
    },
  },
  filter: {
    empty: '拖入或选择过滤的维度',
    title: '筛选',
    tooltip: {
      displayName: '展示名：',
      description: '描述：',
    },
  },
  sort: {
    title: '排序',
    description: '依次按照以下规则排序',
    btn: {
      add: '添加维度/指标',
    },
    input: {
      placeholder: '搜索',
    },
    asc: '升序',
    desc: '降序',
  },
  sidebar: {
    queryButton: '查询',
  },
  entity: {
    exception: {
      referenceNotFount: ({ name }: { name: string | number }) =>
        `${name}无法使用`,
      dataTypeNotMatch: ({ name }: { name: string | number }) =>
        `${name}数据类型变更`,
      noPermission: '没有指标使用权限',
    },
  },
  table: {
    column: {
      empty: '选择指标和维度，查看分析结果',
    },
  },
  cantQuery: {
    reason: '请至少选择一个指标',
  },
  download: {
    fileName: (fileName: string) => `${fileName}_下载数据`,
  },
};
