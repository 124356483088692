import { EDateGranularityType } from '@/typings';
import {
  EDateDynamicOperator,
  EDateValueType,
  EDependencyType,
  EFilterType,
  TDateDynamicValue,
  TFilter,
} from '../types';
import { removeUndefinedDeeplyForObject } from '@/common/utils';
import _ from 'lodash';

export function getCurrentDateDynamicValue(granularity: EDateGranularityType) {
  return getDateDynamicValue(granularity, 0);
}

export function getPreviousDateDynamicValue(granularity: EDateGranularityType) {
  return getDateDynamicValue(granularity, 1);
}

export function getDateDynamicValue(
  granularity: EDateGranularityType,
  value: number,
): TDateDynamicValue {
  return {
    type: EDateValueType.DYNAMIC,
    unit: granularity,
    operator: EDateDynamicOperator.DECREASE,
    value,
  };
}

/**
 * 判断当前的时间粒度是否有更高时间粒度
 */
export function hasHigherGranularity(granularity: EDateGranularityType) {
  // 年没有更高粒度，周由于只能和周对比，所以也没有更高粒度
  return (
    granularity !== EDateGranularityType.WEEK &&
    granularity !== EDateGranularityType.YEAR
  );
}

/**
 * 获取更高一级的时间粒度
 */
export function getHigherGranularity(granularity: EDateGranularityType) {
  switch (granularity) {
    case EDateGranularityType.SECOND:
      return EDateGranularityType.MINUTE;
    case EDateGranularityType.MINUTE:
      return EDateGranularityType.HOUR;
    case EDateGranularityType.HOUR:
      return EDateGranularityType.DAY;
    case EDateGranularityType.DAY:
      return EDateGranularityType.WEEK;
    case EDateGranularityType.MONTH:
      return EDateGranularityType.QUARTER;
    case EDateGranularityType.QUARTER:
      return EDateGranularityType.YEAR;
    default:
      return null;
  }
}

export const genValidString = (str: string) => {
  return str.replace(/\\/g, '\\\\').replace(/"/g, '\\"');
};

// 获取空的筛选条件
export const getEmptyFilter = (type: EDependencyType): TFilter => {
  if (type === EDependencyType.DIMENSION) {
    return {
      dependency: {
        type: EDependencyType.DIMENSION,
        name: '',
      },
      type: EFilterType.INCLUDE,
      values: {
        value: '',
      },
    };
  }

  return {
    dependency: {
      type: EDependencyType.COLUMN,
      columnName: '',
      datasetName: '',
    },
    type: EFilterType.INCLUDE,
    values: {
      value: '',
    },
  };
};

/**
 * 对比两个对象是否相等，忽略值为 undefined 的 key
 */
export function deepEqualIgnoringUndefined(
  obj1: object | null | undefined,
  obj2: object | null | undefined,
): boolean {
  if (obj1 === undefined || obj2 === undefined) {
    return obj1 === obj2;
  }
  const cleanedObj1 = removeUndefinedDeeplyForObject(obj1);
  const cleanedObj2 = removeUndefinedDeeplyForObject(obj2);
  return _.isEqual(cleanedObj1, cleanedObj2);
}

// 判断是否为空的筛选
export const isEmptyFilter = (filter: TFilter) => {
  const { dependency } = filter;
  const { type } = dependency;
  if (type === EDependencyType.COLUMN) {
    if (!dependency.columnName || !dependency.datasetName) {
      return true;
    }
  }
  if (type === EDependencyType.DIMENSION) {
    return !dependency.name;
  }
  return false;
};
