import RcTree from 'rc-tree';
import ResizeObserver, { SizeInfo } from 'rc-resize-observer';
import { ReactComponent as DimensionSvg } from '@/assets/icon/Dimension-light-line.svg';
import { ReactComponent as SearchIcon } from '@/assets/icon/Search-light-line.svg';
import styles from './index.less';
import _ from 'lodash';
import ColumnDataTypeIcon from '@/common/ui/ColumnDataTypeIcon';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Empty, Input, InputRef, Spin, Tree } from '@aloudata/aloudata-design';
import t from '@/locales';
import {
  EColumnDataType,
  EDateGranularityType,
  EOriginDataType,
} from '@/typings';
import { useDebounce } from 'ahooks';
import classnames from 'classnames';
import { filterByKeyword, isMetricTimeDimension } from '@/common/utils';
import PickerTooltip from './Tooltip';
import DragComp from '@/pages/Workbook/Detail/components/DndComp/DragItem';
import { DND_TYPE_ADD_DIMENSION } from '@/pages/Workbook/Detail/common/constants';
import { TAddColumnFn } from '@/pages/Workbook/Detail/components/ConfigArea/getSidebarConfig';
import {
  IDisabledMap,
  IPickerDimension,
  IDimensionPickerRef,
  ITreeNode,
  ISelectedDimensionTreeItem,
} from './type';
import { IDimension } from '@/typings/dimension';
import { metricTimeColumn } from '@/constants';
import { originDataType2DataTypeMap } from '@/common/domain/Formula/constant';
import { ICategory } from '@/typings/category';
import { buildCategoryTree } from '@/pages/Manage/CategoryManage/categoryHelper';
import {
  excludeEmptyCategory,
  fillDimensionListIntoTreeData,
  getGranularityNodeInfo,
} from './helper';
import { getSelectedItemPath } from '@/common/domain/metric/MetricPicker/helper';
import { getEnableCode } from '@/common/globalInfo/appConfig';

interface IConfig {
  showMetricTime?: boolean;
  draggable?: boolean;
  supportDateLikeGranularity?: boolean;
  supportTimeGranularity?: boolean;
}
function PureDimensionList(
  props: IPureDimensionListProps,
  ref: React.ForwardedRef<IDimensionPickerRef>,
) {
  const enableCode = getEnableCode();

  const {
    onClick,
    height,
    dimensions,
    categories,
    loading = false,
    getDisabledMap,
    config = {
      showMetricTime: true,
      draggable: false,
      supportDateLikeGranularity: true,
      supportTimeGranularity: true,
    },
    openInDropdown,
    hideEmptyCategory,
    size,
    isInPopup,
    showAllCategory,
    showSearch = true,
    selected,
    onSelect,
    showUnPublished = false,
    showDataType = true,
    checkedKeys,
    onCheck,
  } = props;

  const {
    showMetricTime = true,
    draggable = false,
    supportDateLikeGranularity = true,
    supportTimeGranularity = true,
  } = config;

  const [keyword, setKeyword] = useState('');
  // 展开时间粒度的维度 id 列表
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
  const [treeSizeInfo, setTreeSizeInfo] = useState<SizeInfo>();
  const inputRef = useRef<InputRef>(null);
  const ICON_SIZE_SMALL = 16;
  const ICON_SIZE_MIDDLE = 20;
  const iconSize = size === 'small' ? ICON_SIZE_SMALL : ICON_SIZE_MIDDLE;
  const treeRef = useRef<RcTree>(null);

  // 是否支持多选
  const checkable = typeof onCheck === 'function';

  // 在 Select 的 renderDropdown 中，openInDropdown 会一直为 true，因此需要提供 ref 便于手动操作
  useImperativeHandle(ref, () => ({
    reset: () => {
      setKeyword('');
      setExpandedKeys([]);
    },
    focus: () => {
      const input = inputRef.current;
      if (input) {
        // 因为 autoFocus 主要用在下拉弹窗这种场景，下拉弹窗出现时渲染有个延迟，不设置延时的话，focus 不会生效
        setTimeout(() => {
          input.focus();
        }, 0);
      }
    },
  }));
  useEffect(() => {
    if (openInDropdown) {
      setKeyword('');
      setExpandedKeys([]);
    }
  }, [openInDropdown]);
  useEffect(() => {
    const input = inputRef.current;
    if (input && openInDropdown) {
      // 因为 autoFocus 主要用在下拉弹窗这种场景，下拉弹窗出现时渲染有个延迟，不设置延时的话，focus 不会生效
      setTimeout(() => {
        input.focus();
      }, 0);
    }
  }, [openInDropdown]);

  const itemList = useMemo(() => {
    let list = _.reduce<IDimension, IPickerDimension[]>(
      dimensions,
      (res, dimension) => {
        res.push({
          ...dimension,
          displayName: dimension.displayName || dimension.name,
          id: dimension.name,
          dataType: originDataType2DataTypeMap[dimension.originDataType],
        });
        return res;
      },
      [],
    );
    // 将指标日期添加到最前面
    const metricTimeCol = {
      ...metricTimeColumn,
      id: metricTimeColumn.name,
      dataType: originDataType2DataTypeMap[metricTimeColumn.originDataType],
    };
    if (supportTimeGranularity) {
      metricTimeCol.originDataType = EOriginDataType.TIMESTAMP;
      metricTimeCol.dataType =
        originDataType2DataTypeMap[EOriginDataType.TIMESTAMP];
    }
    if (showMetricTime) {
      list.unshift(metricTimeCol);
    }
    // 过滤未发布的指标
    if (!showUnPublished) {
      list = list.filter((item) => item.isPublished !== false);
    }

    list = filterByKeyword<IPickerDimension>(
      list,
      [
        (item) => item.displayName,
        (item) => item.dimName,
        (item) => (enableCode ? item.dimCode || '' : ''),
      ],
      keyword,
    );
    const disabledMap =
      typeof getDisabledMap === 'function' ? getDisabledMap(list) : {};
    // 禁用维度
    let listWithDisabled = _.map(list, (dimension) => {
      let disabledReason = disabledMap[dimension.name];
      let disabled = !!disabledReason;
      if (isMetricTimeDimension(dimension)) {
        // 指标日期维度如果可见，则一定可用
        disabled = false;
        disabledReason = '';
      }
      return {
        ...dimension,
        // 本地 mock 环境下，不禁用
        disabled,
        disabledReason,
      };
    });
    // 根据可用性排序，可用的在前面
    listWithDisabled = _.sortBy(
      listWithDisabled,
      (dimension) => dimension.disabled,
    );
    return listWithDisabled;
  }, [
    dimensions,
    supportTimeGranularity,
    showMetricTime,
    showUnPublished,
    keyword,
    getDisabledMap,
    enableCode,
  ]);

  // 将时间粒度扩展为维度项
  const listWithGranularity = useMemo(() => {
    // 多选维度的情况下，暂不考虑时间粒度，因为涉及到时间粒度互斥逻辑，在 tree 组件里不易处理
    if (checkable) {
      return itemList;
    }

    // 将时间类型的维度按照粒度扩充
    const dateGranularityList = [
      EDateGranularityType.YEAR,
      EDateGranularityType.QUARTER,
      EDateGranularityType.MONTH,
      EDateGranularityType.WEEK,
      EDateGranularityType.DAY,
    ];
    const granularityMap: {
      [key in
        | EColumnDataType.DATE
        | EColumnDataType.DATE_TIME]: EDateGranularityType[];
    } = {
      [EColumnDataType.DATE]: dateGranularityList,
      [EColumnDataType.DATE_TIME]: supportTimeGranularity
        ? [
            ...dateGranularityList,
            EDateGranularityType.HOUR,
            EDateGranularityType.MINUTE,
            EDateGranularityType.SECOND,
          ]
        : dateGranularityList,
    };
    return _.reduce<IPickerDimension, IPickerDimension[]>(
      itemList,
      (res, dimensionItem) => {
        const dim = { ...dimensionItem };
        if (isDateLike(dimensionItem.dataType)) {
          dim.granularity = EDateGranularityType.DAY;
          if (!supportDateLikeGranularity) {
            res.push(dim);
            return res;
          }

          if (
            isMetricTimeDimension(dim) &&
            dim.dataType === EColumnDataType.DATE_TIME
          ) {
            dim.children = _.map(
              [
                ...dateGranularityList,
                EDateGranularityType.HOUR,
                EDateGranularityType.MINUTE,
              ],
              (granularity) => {
                return {
                  ...dimensionItem,
                  granularity,
                  id: `${dimensionItem.id}-${granularity}`,
                };
              },
            );
          } else {
            // 时间类型的维度，则扩展出粒度项
            dim.children = _.map(
              granularityMap[
                dimensionItem.dataType as
                  | EColumnDataType.DATE
                  | EColumnDataType.DATE_TIME
              ],
              (granularity) => {
                return {
                  ...dimensionItem,
                  granularity,
                  id: `${dimensionItem.id}-${granularity}`,
                };
              },
            );
          }
        }
        res.push(dim);
        return res;
      },
      [],
    );
  }, [checkable, supportTimeGranularity, itemList, supportDateLikeGranularity]);
  const clickTreeNode = useCallback(
    (dimensionId: string, childId?: string) => {
      let dimension = _.find(listWithGranularity, { id: dimensionId });
      if (childId) {
        dimension = _.find(dimension?.children, { id: childId });
      }
      if (!dimension) {
        console.error('dimension is missing', dimensionId, childId);
        return;
      }
      onClick?.(dimension);
    },
    [listWithGranularity, onClick],
  );
  const getDimensionNodeInTree = useCallback<
    (dimensionItem: IPickerDimension) => ITreeNode
  >(
    (dimensionItem: IPickerDimension) => {
      const { id, dataType, children, disabled, disabledReason } =
        dimensionItem;
      const isDateLikeDimension = isDateLike(dataType);
      //  不使用粒度时，所有维度为叶子节点
      const isLeaf = !isDateLikeDimension || !supportDateLikeGranularity;
      const node = (
        <PickerTooltip
          dimensionItem={dimensionItem}
          reason={disabledReason || ''}
        >
          <div
            className={classnames(styles.item, {
              [styles.usable]: !isDateLikeDimension,
              [styles.draggable]: draggable !== false,
              [styles.disabled]: !!disabled,
              [styles.small]: size === 'small',
            })}
          >
            <div className={styles.dataType}>
              {showDataType ? (
                <ColumnDataTypeIcon
                  dataType={
                    originDataType2DataTypeMap[dimensionItem.originDataType]
                  }
                  size={iconSize}
                />
              ) : (
                <DimensionSvg size={iconSize} />
              )}
            </div>
            <div className={styles.name}>{dimensionItem.displayName}</div>
          </div>
        </PickerTooltip>
      );
      return {
        key: id,
        isLeaf,
        title: (
          <DragComp
            dndType={!dimensionItem.disabled ? DND_TYPE_ADD_DIMENSION : ''}
            data={dimensionItem}
            id={dimensionItem.id}
            draggable={draggable}
          >
            {node}
          </DragComp>
        ),
        children: _.map(children, (subDim) => {
          const nodeInfo = getGranularityNodeInfo(
            subDim.granularity!,
            iconSize,
          );
          return {
            key: subDim.id,
            isLeaf: true,
            parentKey: id,
            title: (
              <DragComp
                dndType={!disabled ? DND_TYPE_ADD_DIMENSION : ''}
                data={subDim}
                id={subDim.id}
                draggable={draggable}
              >
                <PickerTooltip
                  dimensionItem={dimensionItem}
                  reason={disabledReason || ''}
                >
                  <div
                    className={classnames(styles.item, styles.usable, {
                      [styles.disabled]: !!disabled,
                      [styles.draggable]: draggable !== false,
                      [styles.small]: size === 'small',
                    })}
                  >
                    <div className={styles.dataType}>{nodeInfo.icon}</div>
                    <div className={styles.name}>{nodeInfo.name}</div>
                  </div>
                </PickerTooltip>
              </DragComp>
            ),
          };
        }),
      };
    },
    [draggable, supportDateLikeGranularity, iconSize, size, showDataType],
  );
  const isList = keyword !== '';
  // 将维度数据转为树组件需要的数据结构
  const treeData: ITreeNode[] = useMemo(() => {
    const categoryTree = buildCategoryTree(categories);
    if (!_.size(categoryTree)) {
      return [];
    }
    let tree: ITreeNode[] = isList
      ? _.map(listWithGranularity, (dimension) =>
          getDimensionNodeInTree(dimension),
        )
      : fillDimensionListIntoTreeData(
          listWithGranularity,
          categoryTree,
          (dimension) => getDimensionNodeInTree(dimension),
          iconSize,
          showAllCategory,
        );
    if (hideEmptyCategory) {
      tree = excludeEmptyCategory<ITreeNode>(tree);
    }

    return tree;
  }, [
    categories,
    listWithGranularity,
    isList,
    iconSize,
    showAllCategory,
    hideEmptyCategory,
    getDimensionNodeInTree,
  ]);

  // 当 selected 改变时，展开到选中的项的所有父类目
  useEffect(() => {
    if (!selected || !treeData || !treeData.length) {
      return;
    }
    const path = getSelectedItemPath(treeData, selected.key);
    setExpandedKeys((prev) => {
      return _.uniq([...prev, ...path]);
    });
    // 由于树组件的滚动需要在下一帧执行，所以使用setTimeout
    setTimeout(() => {
      treeRef.current?.scrollTo({
        key: selected.key,
      });
    }, 0);
  }, [selected, treeData]);

  const emptyNode = useMemo(() => {
    if (loading) {
      return null;
    }
    if (treeData.length !== 0) {
      return null;
    }
    return <Empty size="small" title={t.workbook.workbook.picker.notFound} />;
  }, [loading, treeData]);

  // 优化性能，防止 tree 的高度频繁变化
  const treeHeight = useDebounce(treeSizeInfo?.offsetHeight, {
    wait: 500,
  });

  return (
    <div
      className={classnames(styles.container, {
        [styles.popup]: isInPopup,
      })}
      style={{ height }}
    >
      <Spin spinning={loading} wrapperClassName={styles.spinWrapper}>
        <>
          {showSearch && (
            <div
              className={classnames(styles.search, {
                [styles.small]: size === 'small',
              })}
            >
              <Input
                bordered={!isInPopup}
                value={keyword}
                onChange={(e) => {
                  setKeyword(e.target.value);
                  // 修改搜索关键词时折叠维度
                  setExpandedKeys([]);
                }}
                allowClear
                placeholder={t.common.list.searchDimension}
                prefix={<SearchIcon size={iconSize} />}
                size={size}
                ref={inputRef}
              />
            </div>
          )}
          <div className={styles.body}>
            <ResizeObserver
              onResize={(sizeInfo) => {
                setTreeSizeInfo(sizeInfo);
              }}
            >
              <div className={styles.list}>
                <Tree.DirectoryTree
                  checkable={checkable}
                  ref={treeRef}
                  treeData={treeData}
                  onCheck={(newCheckedKeys) => {
                    onCheck?.(newCheckedKeys as string[]);
                  }}
                  checkedKeys={checkedKeys}
                  selectedKeys={selected ? [selected.key] : []}
                  onSelect={(selectedKeys, info) => {
                    if (checkable) {
                      return;
                    }
                    // 组件内部合并了 DataNode 和 ITreeNode 的字段
                    const { node } = info;
                    const treeNode = node as unknown as ITreeNode;
                    const selectedData: ISelectedDimensionTreeItem = {
                      key: treeNode.key,
                      isCategory: !!treeNode.isCategory,
                    };
                    onSelect?.(selectedData);
                  }}
                  onClick={(e, treeItemData) => {
                    if (checkable) {
                      return;
                    }
                    const dataItem = treeItemData as ITreeNode;
                    if (dataItem.isCategory) {
                      return;
                    }
                    if (dataItem.parentKey) {
                      // 是维度层级中的子维度
                      clickTreeNode(dataItem.parentKey, dataItem.key);
                      return;
                    }
                    const dim = listWithGranularity.find(
                      (dimItem) => dimItem.id === dataItem.key,
                    );
                    if (!dim) {
                      console.error(
                        'dimension is not found in dimensionPicker',
                        dataItem,
                        listWithGranularity,
                      );
                      return;
                    }
                    if (dim.disabled) {
                      return;
                    }
                    clickTreeNode(dataItem.key);
                  }}
                  onExpand={(keys) => {
                    setExpandedKeys(keys as string[]);
                  }}
                  expandedKeys={expandedKeys}
                  selectable={!!selected}
                  blockNode
                  height={treeHeight}
                  showIcon
                  size={size}
                />
                {emptyNode}
              </div>
            </ResizeObserver>
          </div>
        </>
      </Spin>
    </div>
  );
}
export default forwardRef(PureDimensionList);

export function isDateLike(dataType: EColumnDataType) {
  return [EColumnDataType.DATE, EColumnDataType.DATE_TIME].includes(dataType);
}

export interface IPureDimensionListProps {
  dimensions: IDimension[];
  categories: ICategory[];
  height?: number;
  onClick?: TAddColumnFn;
  config?: IConfig;
  getDisabledMap?: (dimensions: IDimension[]) => IDisabledMap;
  loading?: boolean;
  // TODO: 后续去掉这个属性
  openInDropdown?: boolean; // 在下拉弹窗中渲染内容时，当前是否打开状态
  hideEmptyCategory?: boolean; // 隐藏空分类
  size?: 'small';
  isInPopup?: boolean; // 是否在弹窗中
  // 选中项时回调，可以是指标或类目
  onSelect?: (item: ISelectedDimensionTreeItem) => void;
  // 已选中的项
  selected?: ISelectedDimensionTreeItem;
  checkedKeys?: string[]; // 复选中的维度名称列表
  onCheck?: (checkedKeys: string[]) => void;
  showAllCategory?: boolean; // 是否展示“全部维度”类目
  showSearch?: boolean; // 是否展示搜索框
  showUnPublished?: boolean; // 是否展示未发布的维度
  showDataType?: boolean; // 是否展示数据类型图标
}
