import { EDateGranularityType, EOriginDataType } from '@/typings';
import {
  EDateValueType,
  EFilterType,
  ENumberRangeOperator,
  ERankDirection,
  TFilterConfig,
} from '../../types';
import {
  getCurrentDateDynamicValue,
  getPreviousDateDynamicValue,
} from '../../helper';
import { originDataType2DataTypeMap } from '@/common/domain/Formula/constant';
import { looseDataTypeMap } from '../../constants';
import { operatorLooseDataTypeMap } from '../Operator/CommonOperator';

export function getDefaultValuesByOriginDataType(
  originDataType: EOriginDataType,
  granularity?: EDateGranularityType,
) {
  if (!originDataType) {
    return;
  }
  const newColumnDataType = originDataType2DataTypeMap[originDataType];
  const newLooseDataType = looseDataTypeMap[newColumnDataType];
  const newFilterType = operatorLooseDataTypeMap[newLooseDataType][0].value;
  return getDefaultValues(newFilterType, granularity);
}

export function getDefaultValues(
  type: EFilterType,
  granularity?: EDateGranularityType,
): TFilterConfig {
  const notEmptyGranularity = granularity || EDateGranularityType.DAY;
  const now = new Date().getTime();
  /** 公共筛选类型 */
  if (type === EFilterType.NOT_NULL || type === EFilterType.IS_NULL) {
    return {
      values: undefined,
      type,
      granularity,
    };
  }
  if (EFilterType.EQUAL === type || EFilterType.NOT_EQUAL === type) {
    return {
      type,
      values: [],
      granularity,
    };
  }

  if (
    EFilterType.CONTAIN_ATTRIBUTE === type ||
    EFilterType.NOT_CONTAIN_ATTRIBUTE === type
  ) {
    return {
      type,
      values: [],
    };
  }

  if (type === EFilterType.EQUAL_ATTRIBUTE) {
    return {
      type,
      values: [],
      granularity,
    };
  }

  /** 时间类型 */
  // 时间范围
  if (
    type === EFilterType.DATE_RANGE ||
    type === EFilterType.DATE_OUTSIDE_RANGE
  ) {
    return {
      values: {
        min: {
          type: EDateValueType.FIXED,
          value: now,
        },
        max: {
          type: EDateValueType.FIXED,
          value: now,
        },
      },
      type,
      granularity: notEmptyGranularity,
    };
  }
  // 单个时间
  if (type === EFilterType.DATE_SPECIFY || type === EFilterType.DATE_BEFORE) {
    return {
      values: {
        type: EDateValueType.FIXED,
        value: now,
      },
      type,
      granularity: notEmptyGranularity,
    };
  }
  if (type === EFilterType.DATE_AFTER) {
    return {
      values: {
        type: EDateValueType.FIXED,
        value: now,
      },
      type,
      granularity: notEmptyGranularity,
    };
  }

  if (type === EFilterType.RECENT_N) {
    return {
      values: {
        min: getPreviousDateDynamicValue(notEmptyGranularity),
        max: getCurrentDateDynamicValue(notEmptyGranularity),
      },
      type,
      granularity: notEmptyGranularity,
    };
  }
  /** 数字 */
  // 数字范围
  if (
    type === EFilterType.NUMBER_RANGE ||
    type === EFilterType.NUMBER_OUTSIDE_RANGE
  ) {
    return {
      values: {
        low: {
          operator: ENumberRangeOperator.LESS_OR_EQUAL,
          value: 0,
        },
        high: {
          operator: ENumberRangeOperator.LESS_OR_EQUAL,
          value: 0,
        },
      },
      type,
    };
  }
  // 单个数字
  if (
    EFilterType.NUMBER_EQUAL === type ||
    EFilterType.NUMBER_NOT_EQUAL === type
  ) {
    return {
      type,
      values: [],
      granularity,
    };
  }
  if (
    type === EFilterType.LESS_THAN ||
    type === EFilterType.LESS_THAN_OR_EQUAL
  ) {
    return {
      values: {
        value: 0,
      },
      type,
    };
  }
  if (
    type === EFilterType.GREATER_THAN ||
    type === EFilterType.GREATER_THAN_OR_EQUAL
  ) {
    return {
      values: {
        value: 0,
      },
      type,
    };
  }

  if (
    type === EFilterType.RANK ||
    type === EFilterType.RANK_DENSE ||
    type === EFilterType.ROW_NUMBER
  ) {
    return {
      type,
      values: {
        direction: ERankDirection.DESC,
        value: 1,
      },
    };
  }
  return {
    type,
    values: {
      value: '',
    },
  } as TFilterConfig;
}
