import _ from 'lodash';
import {
  EAggType,
  EDerivedCalculateMode,
  EDerivedCalculateType,
  EMetricOwnerType,
  EMetricType,
  EOnlineStatus,
  EPeriodFormType,
  EWindowChoice,
  IMetricAttributionReportDocument,
  IMetricAttributionTreeDocument,
  IMetricPreviewDocument,
  TMetric,
} from '../../src/typings/metric';
import { mockCategoryList } from '../category/mockData';
import isPossibilityTrue from './isPossibilityTrue';
import getUserInfo from './getUserInfo';
import {
  EColumnDataType,
  EOriginDataType,
  EUnit,
  EDateGranularityType,
} from '../../src/typings';
import {
  EExprMode,
  EFormatConfigType,
  EFormatType,
} from '../../src/services/metric/types';
import {
  EMetricTreeNodeType,
  ESortType,
  IDimension as IWorkbookDimension,
  IMetric as IWorkbookMetric,
} from '../../src/typings/workbook';
import { EUserRole } from '../../src/typings/authority';
import { EFilterMode } from '../../src/pages/Metric/Define/components/Filter';
import {
  ECommonFilterType,
  ECommonFilterValueType,
  EDateFilterType,
  EDateFilterValueType,
  EDateRangeType,
  EFilterOperator,
  ENumberFilterType,
  ENumberFilterValueType,
  ENumberRangeOperator,
  ETextFilterType,
} from '@/common/domain/filter/Filter/filter';
import { EFormulaType } from '../../src/typings/formula';
import {
  TDimension,
  EDimensionType,
  ISimpleDimension,
  IDimension,
} from '../../src/typings/dimension';
import { EDateFilterUnit, TMetricTime } from '../../src/typings/analysisView';
import { EVizType } from '@/common/domain/viz/Echarts/typing';
import { EPropertyType } from '../../src/typings/property';
import { METRIC_TIME } from '../../src/constants';
import {
  DIMENSION_COUNT,
  DIMENSION_NAME_PREFIX_UUID,
  METRIC_CODE_PREFIX_UUID,
  METRIC_COUNT,
} from './constants';
import getMetricMockProperties from './getMetricMockProperties';
import {
  EDateDynamicOperator,
  EDateValueType,
  EDependencyType,
  EFilterType,
} from '@/common/domain/filter/NewFilter/types';
import { originDataType2DataTypeMap } from '@/common/domain/Formula/constant';
import { getUniqId } from '@/common/utils';
import { EColumnType } from '@/typings/dataset';
import { getDefaultValuesByOriginDataType } from '@/common/domain/filter/NewFilter/common/Condition/helper';
import { isDateLikeDataType } from '@/common/utils/helper';
import { defaultAttributionRange } from '@/pages/Metric/Detail/Content/Attribution/AttributionReport/viewModel/global/atom';
import { getInitialLogicNode } from '@aloudata/aloudata-design/dist/LogicTree/helper';

const dimensionList = getDimensionList();
const metricList = getSimpleMetricList();

export function getAtomicMetric(
  metricCode: string,
  index: number,
  withPreviewConfig: boolean,
): TMetric {
  const availableDimensions = getAvailableDimensionList(index);
  const category = [_.sample(mockCategoryList)!];

  return {
    name: `指标展示名_${index}`,
    metricName: `metric_name_${index}`,
    metricCode: `metric_code_${index}`,
    code: metricCode,
    collect: isPossibilityTrue(20),
    owner: getUserInfo(),
    category: category,
    gmtUpdate: new Date('2023-07-06 19:39:00').getTime(),
    originDataType: _.sample([
      EOriginDataType.INTEGER,
      EOriginDataType.FLOAT,
      EOriginDataType.DOUBLE,
    ])!,
    hasDraft: _.sample([true, false]),
    updater: getUserInfo(),
    type: EMetricType.ATOMIC,
    dataType: EColumnDataType.INT,
    isPublished: true,
    status: EOnlineStatus.ONLINE,
    version: 1,
    // @ts-ignore
    atomicComposite: true,
    gmtCreate: new Date('2023-07-06 09:30:00').getTime(),
    description:
      index % 3 !== 0
        ? _.range(100)
            .map(() => `指标描述_${metricCode}`)
            .join(';')
        : '',
    owners: [
      {
        type: EMetricOwnerType.BIZ_OWNER,
        userModel: getUserInfo(),
      },
    ],
    formatConfig: {
      type: EFormatConfigType.CUSTOM,
      format: {
        type: EFormatType.NUMBER,
        decimalPlaces: 1,
        unit: '',
        thousand: true,
      },
    },
    authority: {
      authRoleList: [EUserRole.ADMIN, EUserRole.USAGER, EUserRole.VIEWER],
      canEdit: true,
      canDelete: true,
      canUsage: true,
      canAuth: true,
      canTransfer: true,
    },
    collectCount: _.random(1, 1000),
    unit: EUnit.OTHER,
    layout: {
      aggMethod: {
        columnName: '单件销货成本',
        aggType: EAggType.AVG,
        exprMode: EExprMode.COLUMN,
      },
      filters: {
        type: EFilterMode.NORMAL,
        filter: {
          id: 'MqrmligdxBzWZMPv',
          logicalOperator: EFilterOperator.AND,
          children: [
            {
              id: 'qGBmjkUMxTGSLaDj',
              columnId: '产品名称',
              sheetId: 'dataset_0',
              filter: {
                type: ECommonFilterType.EQUAL,
                values: [
                  {
                    type: ECommonFilterValueType.NULL,
                  },
                ],
              },
              isInnerModel: false,
            },
            {
              id: 'mPJYWglFlrpUQTVT',
              columnId: '产品类别',
              sheetId: 'dataset_0',
              filter: {
                type: ETextFilterType.NOT_INCLUDE,
                values: '都是',
              },
              isInnerModel: false,
            },
            {
              id: 'sRFECpmCdYBpQVYO',
              columnId: '产品类别',
              sheetId: 'dataset_0',
              filter: {
                type: ETextFilterType.INCLUDE,
                values: '啥范德萨',
              },
              isInnerModel: false,
            },
            {
              id: 'pNkmilDgBBlFDAWl',
              columnId: '产品类别',
              sheetId: 'dataset_0',
              filter: {
                type: ETextFilterType.NOT_INCLUDE,
                values: '地方撒',
              },
              isInnerModel: false,
            },
            {
              id: 'gJhSipmLsRjVYzWs',
              columnId: '产品编码',
              sheetId: 'dataset_0',
              filter: {
                type: ETextFilterType.START_WITH,
                values: '三大',
              },
              isInnerModel: false,
            },
            {
              id: 'KQAGgVlHFBEMIJyS',
              columnId: '产品编码',
              sheetId: 'dataset_0',
              filter: {
                type: ECommonFilterType.NOT_EQUAL,
                values: [
                  {
                    type: ECommonFilterValueType.NULL,
                  },
                ],
              },
              isInnerModel: false,
            },
            {
              id: 'AAQROZlqUmWRYeTp',
              columnId: '产品编码',
              sheetId: 'dataset_0',
              filter: {
                type: ETextFilterType.NOT_END_WITH,
                values: '范德萨',
              },
              isInnerModel: false,
            },
            {
              id: 'EFhJXeRdekoDLWLs',
              columnId: '分区日期',
              sheetId: 'dataset_0',
              filter: {
                values: {
                  type: EDateRangeType.RANGE,
                  includeToday: true,
                  values: {
                    min: {
                      value: 1689955200000,
                      type: EDateFilterValueType.FIXED,
                    },
                    max: {
                      value: 1690646399000,
                      type: EDateFilterValueType.FIXED,
                    },
                  },
                },
                includeNull: true,
                type: EDateFilterType.RANGE,
              },
              isInnerModel: false,
            },
            {
              id: 'dUGJLBKjVzjzIOfR',
              columnId: '分区日期',
              sheetId: 'dataset_0',
              filter: {
                type: EDateFilterType.RANGE,
                includeNull: true,
                values: {
                  type: EDateRangeType.RANGE,
                  includeToday: true,
                  values: {
                    min: {
                      value: null,
                      type: EDateFilterValueType.FIXED,
                    },
                    max: {
                      value: null,
                      type: EDateFilterValueType.FIXED,
                    },
                  },
                },
              },
              isInnerModel: false,
            },
            {
              id: 'kjnlatCklRQrPHnh',
              columnId: '分区日期',
              sheetId: 'dataset_0',
              filter: {
                type: ECommonFilterType.NOT_EQUAL,
                values: [
                  {
                    type: ECommonFilterValueType.NULL,
                  },
                ],
              },
              isInnerModel: false,
            },
            {
              id: 'VXAvxDRdrSOAXuOD',
              columnId: '年月',
              sheetId: 'dataset_0',
              filter: {
                type: ECommonFilterType.EQUAL,
                values: [
                  {
                    type: ECommonFilterValueType.NULL,
                  },
                ],
              },
              isInnerModel: false,
            },
            {
              id: 'HejdIoLoRjoJTWwM',
              columnId: '年月',
              sheetId: 'dataset_0',
              filter: {
                values: {
                  type: EDateRangeType.SPECIFY,
                  values: 1689769428511,
                },
                includeNull: true,
                type: EDateFilterType.RANGE,
              },
              isInnerModel: false,
            },
            {
              id: 'rClMiPgygfSLicrb',
              columnId: '年月',
              sheetId: 'dataset_0',
              filter: {
                values: {
                  type: EDateRangeType.BEFORE,
                  values: {
                    includeSelected: true,
                    value: 1690028641577,
                  },
                },
                includeNull: true,
                type: EDateFilterType.RANGE,
              },
              isInnerModel: false,
            },
            {
              id: 'UHvIPmDVVCKHNWqy',
              columnId: '年月',
              sheetId: 'dataset_0',
              filter: {
                values: {
                  type: EDateRangeType.AFTER,
                  values: {
                    includeSelected: true,
                    value: 1689164665463,
                  },
                },
                includeNull: true,
                type: EDateFilterType.RANGE,
              },
              isInnerModel: false,
            },
            {
              id: 'hdyqbYHbBetyHdNg',
              columnId: '库存余量',
              sheetId: 'dataset_0',
              filter: {
                type: ENumberFilterType.RANGE,
                values: {
                  low: {
                    type: ENumberFilterValueType.NUMBER,
                    operator: ENumberRangeOperator.LESS,
                    value: 1,
                  },
                  high: {
                    type: ENumberFilterValueType.NUMBER,
                    operator: ENumberRangeOperator.LESSOREQUAL,
                    value: 22,
                  },
                },
              },
              isInnerModel: false,
            },
            {
              id: 'SXGUHOAFNNOqaMJv',
              columnId: '日期时间',
              sheetId: 'dataset_1',
              filter: {
                values: {
                  type: EDateRangeType.AFTER,
                  values: {
                    includeSelected: true,
                    value: 1689858817891,
                  },
                },
                includeNull: true,
                type: EDateFilterType.RANGE,
              },
              isInnerModel: false,
            },
          ],
        },
      },
    },
    caliber: {
      datasetName: 'dataset_7',
      metricTime: '年月',
      metricTimeDataType: EOriginDataType.DATETIME,
      enableNonAdditiveDimensions: true,
      nonAdditiveDimensions: {
        dimensions: ['库存余量', '产品类别', '仓库'],
        windowGroupings: ['单件销货成本'],
        windowChoice: EWindowChoice.MIN,
      },
      formula: {
        type: EFormulaType.EMPTY,
      },
    },
    format: '',
    availableDimensions,
    previewConfig: withPreviewConfig
      ? {
          layout: getMockMetricPreviewDocument(
            dimensionList.slice(0, 2),
            metricList.slice(0, 4),
          ),
          dependencies: {
            dimensions: dimensionList.slice(0, 2),
            metrics: metricList.slice(0, 4),
          },
        }
      : undefined,
    // 用 withPreviewConfig 来判断是否需要生成 attributionTree，因为不判断的话会循环依赖导致 metricList 为 undefined
    attributionTree: withPreviewConfig
      ? {
          layout: getMockMetricAttributionTreeDocument(metricList.slice(0, 4)),
          dependencies: {
            dimensions: [],
            metrics: metricList.slice(0, 4),
          },
        }
      : undefined,
    attributionReport: withPreviewConfig
      ? {
          layout: getMockMetricAttributionReportDocument(
            dimensionList.slice(0, 2),
          ),
          dependencies: {
            dimensions: [],
            metrics: [],
          },
        }
      : undefined,
    properties: getMetricMockProperties(category, EUnit.OTHER),
  };
}

function getDerivedMetric(
  metricCode: string,
  index: number,
  withPreviewConfig: boolean,
): TMetric {
  const availableDimensions = getAvailableDimensionList(index);
  const category = [_.sample(mockCategoryList)!];
  return {
    name: `指标展示名_${index}`,
    metricName: `metric_name_${index}`,
    metricCode: `metric_code_${index}`,
    code: metricCode,
    owner: getUserInfo(),
    unit: EUnit.OTHER,
    collect: isPossibilityTrue(20),
    gmtUpdate: new Date('2023-07-06 19:39:00').getTime(),
    originDataType: _.sample([
      EOriginDataType.INTEGER,
      EOriginDataType.FLOAT,
      EOriginDataType.DOUBLE,
    ])!,
    hasDraft: _.sample([true, false]),
    // @ts-ignore
    atomicComposite: false,
    type: EMetricType.DERIVED,
    collectCount: _.random(1, 1000),
    formatConfig: {
      type: EFormatType.NUMBER,
    },
    dataType: EColumnDataType.INT,
    isPublished: true,
    status: _.sample([EOnlineStatus.OFFLINE, EOnlineStatus.ONLINE]),
    version: 1,
    gmtCreate: new Date('2023-07-06 09:30:00').getTime(),
    description:
      index % 3 !== 0
        ? _.range(100)
            .map(() => `指标描述_${index}`)
            .join(';')
        : '',
    owners: [
      {
        type: EMetricOwnerType.BIZ_OWNER,
        userModel: getUserInfo(),
      },
    ],
    category: category,
    updater: getUserInfo(),
    format: '',
    caliber: {
      refMetricCode: 'atomic',
      calculateType: EDerivedCalculateType.MOVING_CALCULATE,
      extMode: {
        calculateType: EDerivedCalculateMode.MAX,
        granularity: EDateGranularityType.DAY,
      },
      movingCalculate: {
        // 移动计算周期
        granularity: EDateGranularityType.DAY,
        aggType: EAggType.AVG,
      },
    },
    layout: {
      period: EPeriodFormType.NEARLY_180_DAYS,
      filters: {
        type: EFilterMode.NORMAL,
        filter: {
          id: 'MqrmligdxBzWZMPv',
          logicalOperator: EFilterOperator.AND,
          children: [
            {
              id: 'qGBmjkUMxTGSLaDj',
              dependentId: availableDimensions[0].name,
              filter: {
                type: ECommonFilterType.EQUAL,
                values: [
                  {
                    type: ECommonFilterValueType.NULL,
                  },
                ],
              },
            },
            {
              id: 'mPJYWglFlrpUQTVT',
              dependentId: availableDimensions[2].name,
              filter: {
                type: ETextFilterType.NOT_INCLUDE,
                values: '都是',
              },
            },
          ],
        },
      },
    },
    availableDimensions,
    authority: {
      authRoleList: [EUserRole.ADMIN, EUserRole.USAGER, EUserRole.VIEWER],
      canEdit: true,
      canDelete: true,
      canUsage: true,
      canAuth: true,
      canTransfer: true,
    },
    previewConfig: withPreviewConfig
      ? {
          layout: getMockMetricPreviewDocument(
            dimensionList.slice(0, 2),
            metricList.slice(0, 4),
          ),
          dependencies: {
            dimensions: dimensionList.slice(0, 2),
            metrics: metricList.slice(0, 4),
          },
        }
      : undefined,
    // 用 withPreviewConfig 来判断是否需要生成 attributionTree，因为不判断的话会循环依赖导致 metricList 为 undefined
    attributionTree: withPreviewConfig
      ? {
          layout: getMockMetricAttributionTreeDocument(metricList.slice(0, 4)),
          dependencies: {
            dimensions: [],
            metrics: metricList.slice(0, 4),
          },
        }
      : undefined,
    attributionReport: withPreviewConfig
      ? {
          layout: getMockMetricAttributionReportDocument(
            dimensionList.slice(0, 2),
          ),
          dependencies: {
            dimensions: [],
            metrics: [],
          },
        }
      : undefined,
    properties: getMetricMockProperties(category, EUnit.OTHER),
  };
}

export function getAvailableDimensionList(
  metricIndex: number,
): ISimpleDimension[] {
  const start = Math.floor(metricIndex / 10) * 10;
  const end = start + 10;

  return _.map(_.range(start, end), (i) => {
    const dimension = getDimension(i.toString());
    return {
      name: dimension.name,
      dimName: dimension.dimName,
      dimCode: dimension.dimCode,
      isEnable: dimension.isEnable,
      displayName: dimension.displayName,
      description: dimension.description,
      isPublished: true,
      hasPermission: dimension.hasPermission,
      originDataType: dimension.originDataType,
      category: [_.sample(mockCategoryList)!],
      type: EDimensionType.CUSTOM,
      owner: getUserInfo(),
      id: dimension.name,
      authority: {
        authRoleList: [EUserRole.ADMIN, EUserRole.USAGER, EUserRole.VIEWER],
        canCreate: true,
        canEdit: true,
        canDelete: true,
        canUsage: true,
        canAuth: true,
        canTransfer: true,
      },
    };
  });
}

export function getSimpleMetricList(withPreviewConfig = false) {
  return _.map(_.range(METRIC_COUNT), (index) =>
    getMockSimpleMetricInfo(
      index === 0 ? 'derived' : `atomic_${index}`,
      index,
      withPreviewConfig,
    ),
  );
}

export function getMockSimpleMetricInfo(
  metricCode: string,
  index: number,
  withPreviewConfig: boolean = false,
): TMetric {
  if (metricCode === `derived`) {
    return getDerivedMetric(
      `${METRIC_CODE_PREFIX_UUID}_${metricCode}`,
      index,
      withPreviewConfig,
    );
  }
  return getAtomicMetric(
    `${METRIC_CODE_PREFIX_UUID}_${metricCode}`,
    index,
    withPreviewConfig,
  );
}

export function getDimension(
  name: string,
  type?: EDimensionType,
  originDataType?: EOriginDataType,
): TDimension {
  const innerType =
    type || _.sample([EDimensionType.BIND, EDimensionType.CUSTOM])!;
  const innerOriginDataType = originDataType || EOriginDataType.INT;
  const properties = {
    [EPropertyType.BASE]: {
      dimName: {
        value: 'dim_code',
      },
      dimCode: {
        value: 'dim_code',
      },
      dimDisplayName: {
        value: 'dim_cn_name',
      },
    },
    [EPropertyType.BUSINESS]: {
      dimDescription: {
        value:
          'dim_describedim_describedim_describedim_describedim_describedim_describedim_describe',
      },
      dimCategoryId: {
        value: [_.sample(mockCategoryList)!],
      },
    },
    [EPropertyType.MANAGE]: {},
    [EPropertyType.TECHNOLOGY]: {},
  };
  if (innerType === EDimensionType.BIND) {
    return {
      name: `${DIMENSION_NAME_PREFIX_UUID}_${name}`,
      displayName: `维度展示名_${name}`,
      dimName: `dim_name_${name}`,
      dimCode: `dim_code_${name}`,
      isEnable: true,
      type: innerType,
      description:
        _.random(100) > 30
          ? 'descriptiondescriptiondescriptiondescriptiondescriptiondescription'
          : '',
      category: [_.sample(mockCategoryList)!],
      originDataType: innerOriginDataType,
      isPublished: _.sample([true, false]),
      status: _.sample([EOnlineStatus.OFFLINE, EOnlineStatus.ONLINE]),
      hasDraft: _.sample([true, false]),
      dimensionDataType: EColumnDataType.DECIMAL,
      authority: {
        canEdit: !!_.sample([true, false]),
        canDelete: !!_.sample([true, false]),
        canUsage: !!_.sample([true, false]),
        canAuth: !!_.sample([true, false]),
        canTransfer: !!_.sample([true, false]),
      },
      hasPermission: true,
      datasetName: 'dataset_name',
      config: {
        columnName: 'column_name',
      },
      properties,
    };
  }

  return {
    name: `${DIMENSION_NAME_PREFIX_UUID}_${name}`,
    displayName: `维度展示名_${name}`,
    dimName: `dim_name_${name}`,
    dimCode: `dim_code_${name}`,
    isEnable: true,
    isPublished: _.sample([true, false]),
    status: _.sample([EOnlineStatus.OFFLINE, EOnlineStatus.ONLINE]),
    hasDraft: _.sample([true, false]),
    type: EDimensionType.CUSTOM,
    description:
      'descriptiondescriptiondescriptiondescriptiondescriptiondescription',

    category: [_.sample(mockCategoryList)!],
    originDataType: _.sample([
      // EOriginDataType.INT,
      // EOriginDataType.DATE,
      EOriginDataType.DATETIME,
      // EOriginDataType.VARCHAR,
      EOriginDataType.BOOLEAN,
    ])!,
    dimensionDataType: EColumnDataType.DECIMAL,
    authority: {
      canEdit: !!_.sample([true, false]),
      canDelete: !!_.sample([true, false]),
      canUsage: !!_.sample([true, false]),
      canAuth: !!_.sample([true, false]),
      canTransfer: !!_.sample([true, false]),
    },
    hasPermission: true,
    config: {
      formula: {
        type: EFormulaType.COLUMN,
        path: ['table_name', 'column_name'],
      },
    },
    datasetName: 'dataset_name',
    layout: {
      expr: '[table/column]',
    },
    properties,
  };
}

export function getDimensionList() {
  return _.map(_.range(DIMENSION_COUNT), (item, index) =>
    getDimension(index.toString()),
  );
}

export const metricTimeFilter: TMetricTime = {
  active: false,
  granularity: EDateGranularityType.DAY,
  type: EFilterType.DATE_RANGE,
  values: {
    min: {
      operator: EDateDynamicOperator.DECREASE,
      type: EDateValueType.DYNAMIC,
      value: 179,
      unit: EDateGranularityType.DAY,
    },
    max: {
      operator: EDateDynamicOperator.DECREASE,
      type: EDateValueType.DYNAMIC,
      value: 0,
      unit: EDateGranularityType.DAY,
    },
  },
};

export function getMockMetricAttributionReportDocument(
  dimensions: IDimension[],
): IMetricAttributionReportDocument {
  return {
    attributionRange: defaultAttributionRange,
    dimensions: dimensions.map((dim) => {
      const res: {
        name: string;
        granularity?: EDateGranularityType;
      } = {
        name: dim.name,
      };
      if (isDateLikeDataType(originDataType2DataTypeMap[dim.originDataType])) {
        res.granularity = EDateGranularityType.DAY;
      }
      return res;
    }),
  };
}

export function getMockMetricAttributionTreeDocument(
  metrics: TMetric[],
): IMetricAttributionTreeDocument {
  const list = metrics.slice(0, 4);
  const metricsMap = _.reduce<TMetric, Record<string, IWorkbookMetric>>(
    list,
    (acc, metric) => {
      const metricId = metric.code;
      acc[metricId] = generateMetric(metric.code, metricId);
      return acc;
    },
    {},
  );
  return {
    attributionRange: defaultAttributionRange,
    mainMetric: metrics[0].code,
    metricsMap,
    subMetrics: metrics.map((metric) => metric.code),
    treeNodes: [
      {
        id: getUniqId(),
        metricId: metrics[0].code,
        type: EMetricTreeNodeType.METRIC,
      },
    ],
    filterDependency: {},
    filters: [],
  };
}

export function getMockMetricPreviewDocument(
  dims: IDimension[],
  metrics: TMetric[],
): IMetricPreviewDocument {
  const dimensionsMap = _.reduce<
    IDimension,
    Record<string, IWorkbookDimension>
  >(
    dims,
    (acc, dim, index) => {
      const dimensionId = dim.name;
      acc[dimensionId] = generateDimension(dim.name, dimensionId);
      return acc;
    },
    {},
  );
  const metricsMap = _.reduce<TMetric, Record<string, IWorkbookMetric>>(
    metrics.slice(0, 4),
    (acc, metric, index) => {
      let metricCode = metric.code;
      const selfMetricIndex = index === 0;
      if (selfMetricIndex) {
        metricCode = '__SELF__adadadad';
      }
      const metricId = metricCode;
      const metricItem = generateMetric(metricCode, metricId);
      acc[metricId] = metricItem;
      if (selfMetricIndex) {
        metricItem.displayName = metricItem.name = '当前指标';
      }
      return acc;
    },
    {},
  );
  const dimension = dims[0];
  const depId = getUniqId();

  return {
    filterDependency: dimension
      ? {
          [depId]: {
            id: depId,
            def: {
              dimensionName: dimension.name,
            },
            displayName: dimension.displayName,
            name: dimension.name,
            dimensionDisplayName: dimension.displayName,
            dataType: originDataType2DataTypeMap[dimension.originDataType],
            originDataType: dimension.originDataType,
            description: dimension.description,
            columnType: EColumnType.DIMENSION,
            isFillConfig: false,
          },
        }
      : {},
    filters: dimension
      ? [
          {
            id: getUniqId(),
            ...getDefaultValuesByOriginDataType(
              dimension.originDataType,
              EDateGranularityType.DAY,
            )!,
            dependency: {
              type: EDependencyType.DIMENSION,
              name: depId,
            },
          },
        ]
      : [],
    metricTimeFilter: metricTimeFilter,
    dimensionsMap,
    metricsMap,
    dimensions: Object.keys(dimensionsMap),
    metrics: Object.keys(metricsMap),
    chartType: 'TABLE',
    sorts: [
      {
        id: '__SELF__adadadad',
        type: ESortType.METRIC,
        isAsc: true,
      },
    ],
    version: 1,
  };
}

export function generateDimension(
  dimensionName: string,
  id: string,
): IWorkbookDimension {
  const dim = _.find(dimensionList, { name: dimensionName })!;
  const dataType = originDataType2DataTypeMap[dim.originDataType];
  return {
    def: {
      dimensionName,
    },
    id,
    sort: {
      id,
      type: ESortType.DIMENSION,
      isAsc: true,
    },
    displayName: dim.displayName,
    dimensionDisplayName: dim.displayName,
    name: dim.name,
    granularity:
      dataType === EColumnDataType.DATE ||
      dataType === EColumnDataType.DATE_TIME
        ? _(EDateGranularityType).values().sample()!
        : undefined,
    dataType: dataType,
    originDataType: dim.originDataType,
    description: dim.description,
  };
}

export function generateMetric(code: string, id: string): IWorkbookMetric {
  const originDataType = _(EOriginDataType).values().sample()!;
  const dataType = originDataType2DataTypeMap[originDataType];
  return {
    def: {
      code: `${code}`,
    },
    id,
    name: `Metric ${code}`,
    displayName: `Metric ${code}`,
    originDataType: originDataType,
    dataType: dataType,
    description: 'Metric description',
    unit: EUnit.OTHER,
    filterDependency: {},
    filters: getInitialLogicNode([]),
    dateLimit: {
      disabled: true,
      preAggs: [],
      isPreAggCustom: false,
    },
  };
}
