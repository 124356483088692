import {
  EDependencyType,
  EFilterType,
  IMatchMetricValues,
  TFilter,
  TMatchMetricInnerValues,
} from '../../types';
import { useEffect, useState } from 'react';
import MetricPicker from '@/common/domain/metric/MetricPicker';
import { TMetric } from '@/typings/metric';
import t from '@/locales';
import { Select, Spin } from '@aloudata/aloudata-design';
import styles from './index.less';
import ColumnDataTypeIcon from '@/common/ui/ColumnDataTypeIcon';
import { originDataType2DataTypeMap } from '@/common/domain/Formula/constant';
import MatchMetricOperator from '../Operator/MatchMetricOperator';
import { getDefaultValues } from '../Condition/helper';
import { EColumnDataType, EDateGranularityType } from '@/typings';
import ValueNode from '../Condition/ValueNode';
import FilterDependencyValuesSelect from '@/common/domain/filter/FilterDependencyValuesSelect';
import { EFilterType as EFilterDepType } from '@/common/domain/filter/Filter/types';
import { transformValues } from '@/common/domain/filter/Filter/helper';
import { useQueryMetricMatchesCandidateMetrics } from '@/services/metric';
import { IDisabledMap } from '@/common/domain/metric/MetricPicker/PureMetricList';
import _ from 'lodash';

interface IProps {
  dimensionName: string;
  metricCode: string;
  values: IMatchMetricValues['values'];
  onChange: (newValues: IMatchMetricValues) => void;
  currentCode?: string;
}

export default function MatchMetric({
  dimensionName,
  metricCode,
  values,
  onChange,
  currentCode,
}: IProps) {
  const [open, setOpen] = useState(false);
  const { loading, run: queryMetricMatchesCandidateMetrics } =
    useQueryMetricMatchesCandidateMetrics();
  const [metrics, setMetrics] = useState<TMetric[]>([]);
  const [disabledMap, setDisabledMap] = useState<IDisabledMap>({});

  useEffect(() => {
    if (!dimensionName) return;
    queryMetricMatchesCandidateMetrics({
      dimensionName,
      metricCode: currentCode,
    }).then((res) => {
      const initialDisabledMap: IDisabledMap = {};
      const { metrics: allMetrics, disabledMetrics } =
        res.queryMetricMatchesCandidate;
      setMetrics(allMetrics || []);
      setDisabledMap(
        _.reduce(
          disabledMetrics,
          (acc, item) => {
            acc[item.code] = item.message;
            return acc;
          },
          initialDisabledMap,
        ) || {},
      );
    });
  }, [dimensionName, queryMetricMatchesCandidateMetrics, currentCode]);

  const selectMetric = metrics.find((item) => item.code === metricCode);

  const onChangeMetric = (metric: TMetric) => {
    const { originDataType } = metric;
    const dataType = originDataType2DataTypeMap[originDataType];
    const val =
      dataType === EColumnDataType.INT ||
      dataType === EColumnDataType.DECIMAL ||
      dataType === EColumnDataType.DOUBLE
        ? (getDefaultValues(
            EFilterType.NUMBER_EQUAL,
            EDateGranularityType.DAY,
          ) as TMatchMetricInnerValues)
        : (getDefaultValues(
            EFilterType.EQUAL,
            EDateGranularityType.DAY,
          ) as TMatchMetricInnerValues);
    onChange({
      dependency: {
        code: metric.code,
      },
      values: val,
    });
    setOpen(false);
  };

  const onChangeOperator = (type: EFilterType) => {
    onChange({
      dependency: {
        code: metricCode,
      },
      values: getDefaultValues(
        type,
        EDateGranularityType.DAY,
      ) as TMatchMetricInnerValues,
    });
  };

  const onChangeInnerValues = (val: TFilter) => {
    Reflect.deleteProperty(val, 'dependency');
    onChange({
      dependency: {
        code: metricCode,
      },
      values: val as TMatchMetricInnerValues,
    });
  };

  if (loading) return <Spin size="small" />;

  return (
    <div className={styles.wrap}>
      <Select
        open={open}
        className={styles.select}
        placeholder={t.filter.filter.placeholder}
        onDropdownVisibleChange={(isOpen) => {
          setOpen(isOpen);
        }}
        prefix={
          selectMetric && (
            <ColumnDataTypeIcon
              dataType={originDataType2DataTypeMap[selectMetric.originDataType]}
            />
          )
        }
        value={selectMetric?.name}
        dropdownRender={() => (
          <MetricPicker
            metrics={metrics}
            getDisabledMap={() => disabledMap}
            isInPopup
            height={300}
            onClick={onChangeMetric}
          />
        )}
      />
      {selectMetric && (
        <MatchMetricOperator
          className={styles.operator}
          value={values.type}
          onChange={onChangeOperator}
          columnDataType={
            originDataType2DataTypeMap[selectMetric.originDataType]
          }
        />
      )}
      {selectMetric && (
        <ValueNode
          key={`${dimensionName}_${values.type}`}
          value={{
            dependency: {
              type: EDependencyType.DIMENSION,
              name: dimensionName,
            },
            ...values,
          }}
          renderValuesPicker={({
            value: metricValues,
            onChange: onChangeDimValues,
          }) => {
            return (
              <FilterDependencyValuesSelect
                dependency={{
                  type: EFilterDepType.METRIC_FILTER,
                  code: metricCode,
                  dimensionName,
                }}
                isSingleValue
                value={transformValues(metricValues)}
                onSubmit={(newMetricValues) => {
                  onChangeDimValues(newMetricValues);
                }}
                dataType={
                  originDataType2DataTypeMap[selectMetric.originDataType]
                }
              />
            );
          }}
          onChange={onChangeInnerValues}
          originDataType={selectMetric.originDataType}
        />
      )}
    </div>
  );
}
