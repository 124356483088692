import t from '@/locales';
import styles from './index.less';
import DimensionPicker, {
  IProps as IDimensionPickerProps,
} from '@/common/domain/dimension/DimensionPicker';
import {
  IDimensionPickerValue,
  IPickerDimension,
} from '@/common/domain/dimension/DimensionPicker/type';
import { metricTimeColumn } from '@/constants';
import { IDimension } from '@/typings/dimension';
import { Select } from '@aloudata/aloudata-design';
import { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import { originDataType2DataTypeMap } from '@/common/domain/Formula/constant';
import ColumnDataTypeIcon from '@/common/ui/ColumnDataTypeIcon';
import { getDimensionDisplayName } from '../../helper';

export default function DimensionSelect(props: IProps) {
  const {
    dimensions,
    value,
    onChange = () => {},
    className,
    size,
    disabled,
    ...others
  } = props;
  const [open, setOpen] = useState(false);

  const dimList = useMemo(() => dimensions || [], [dimensions]);

  const onPickDimension = useCallback(
    (dim: IPickerDimension) => {
      onChange({
        name: dim.name,
        granularity: dim.granularity,
        originDataType: dim.originDataType,
      });
      setOpen(false);
    },
    [onChange],
  );

  const selectedLabel = getDimensionDisplayName({
    dimensionName: value.name,
    dimensionDisplayName: value.displayName,
    granularity: value.granularity,
    dimensions: dimList,
  });

  const dimension = [metricTimeColumn, ...dimensions].find(
    (dim) => dim.name === value.name,
  );
  const val = selectedLabel
    ? {
        value: '',
        label: selectedLabel,
      }
    : undefined;

  const finalOriginDataType = value.originDataType || dimension?.originDataType;
  const dataTypeIcon = finalOriginDataType ? (
    <ColumnDataTypeIcon
      disabled={disabled}
      dataType={originDataType2DataTypeMap[finalOriginDataType]}
      granularity={value.granularity}
    />
  ) : undefined;

  const SMALL_SIZE_ICON = 16;
  const DEFAULT_SIZE_ICON = 20;
  return (
    <Select
      value={val}
      prefix={dataTypeIcon}
      prefixWidth={size === 'small' ? SMALL_SIZE_ICON : DEFAULT_SIZE_ICON}
      placeholder={t.components.dimensionPicker.placeholder}
      labelInValue
      onChange={onPickDimension}
      dropdownMatchSelectWidth={false}
      className={classNames(className, styles.select)}
      open={open}
      size={size}
      onDropdownVisibleChange={setOpen}
      disabled={disabled}
      dropdownRender={() => (
        <div className={styles.dropdownContent}>
          <DimensionPicker
            dimensions={dimList}
            height={300}
            hideEmptyCategory
            {...others}
            onClick={onPickDimension}
            isInPopup
            size={size}
          />
        </div>
      )}
    />
  );
}

interface IProps extends Omit<IDimensionPickerProps, 'dimensions'> {
  dimensions: IDimension[];
  className?: string;
  value: IDimensionPickerValue;
  onChange: (value: IDimensionPickerValue) => void;
  disabled?: boolean;
}
