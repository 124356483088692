import { graphql, rest } from 'msw';
import {
  getDatasetRelations,
  getMockDatasetInfo,
  getMockTableSourceInfo,
  getRestfulResponse,
} from '../utils';
import {
  EColumnType,
  ESourceType,
  ITableColumn,
} from '../../src/typings/dataset';
import { IExecuteSqlRes } from '../../src/services/dataset/types';
import _ from 'lodash';
import { originDataType2DataTypeMap } from '../../src/common/domain/Formula/constant';
import { EColumnDataType } from '../../src/typings';
import { getUniqId } from '../../src/common/utils';

export const dataset = [
  graphql.query('querySourceData', (req, res, ctx) => {
    const { content, type } = req.variables;
    return res(
      ctx.data({
        querySourceData: getMockTableSourceInfo(type, content),
      }),
    );
  }),

  graphql.query('getSourceTable', (req, res, ctx) => {
    return res(
      ctx.data({
        getSourceTable: Array.from({ length: 10 }).map((v, i) =>
          getMockTableSourceInfo(ESourceType.TABLE, `table_name_${i}`),
        ),
      }),
    );
  }),

  graphql.query('queryDatasetInfo', (req, res, ctx) => {
    return res(
      ctx.data({
        queryDatasetInfo: getMockDatasetInfo(req.variables.name),
      }),
    );
  }),

  graphql.query('queryDatasetList', (req, res, ctx) => {
    const list = _.map(_.range(20), (index) =>
      getMockDatasetInfo(`dataset_${index}`),
    );
    return res(
      ctx.data({
        queryDatasetList: {
          data: list,
          hasNext: true,
          total: 100,
          pageNumber: 1,
          pageSize: 20,
        },
      }),
      ctx.delay(500),
    );
  }),
  graphql.query('checkSqlConfig', (req, res, ctx) => {
    return res(
      ctx.data({
        checkSqlConfig: true,
      }),
      ctx.delay(1000),
    );
  }),
  graphql.mutation('createRelation', (req, res, ctx) => {
    return res(
      ctx.data({
        createRelation: true,
      }),
      ctx.delay(2000),
    );
  }),

  graphql.mutation('updateRelation', (req, res, ctx) => {
    return res(
      ctx.data({
        updateRelation: true,
      }),
      ctx.delay(1000),
    );
  }),

  graphql.mutation('deleteRelation', (req, res, ctx) => {
    return res(
      ctx.data({
        deleteRelation: true,
      }),
      ctx.delay(1000),
    );
  }),

  rest.post('/dataset/checkRelations', (req, res, ctx) => {
    return res(
      ctx.json(
        getRestfulResponse({
          result: _.random(0, 100) > 10, // 制造一定的失败概率
          errorMsg: 'checkRelations error, please change dstDataset',
        }),
      ),
    );
  }),
  rest.post('/dataset/check', (req, res, ctx) => {
    return res(ctx.json(getRestfulResponse([])));
  }),

  graphql.query('queryDownDatasets', (req, res, ctx) => {
    return res(
      ctx.data({
        queryDownDatasets: _.map(_.range(5), (index) =>
          getMockDatasetInfo(`dataset_${index}`),
        ),
      }),
    );
  }),

  rest.post('/dataset/checkDataset', (req, res, ctx) => {
    return res(
      ctx.json(
        getRestfulResponse({
          result: true,
        }),
      ),
    );
  }),

  graphql.mutation('createDataset', (req, res, ctx) => {
    return res(
      ctx.data({
        createDataset: true,
      }),
      ctx.delay(1000),
    );
  }),

  graphql.mutation('updateDataset', (req, res, ctx) => {
    return res(
      ctx.data({
        updateDataset: true,
      }),
    );
  }),

  graphql.mutation('deleteDataset', (req, res, ctx) => {
    return res(
      ctx.data({
        deleteDataset: true,
      }),
    );
  }),

  graphql.mutation('columnDimensionDelete', (req, res, ctx) => {
    return res(
      ctx.data({
        columnDimensionDelete: true,
      }),
    );
  }),
  graphql.mutation('setupPartitionMapping', (req, res, ctx) => {
    return res(
      ctx.data({
        setupPartitionMapping: true,
      }),
    );
  }),

  graphql.query('queryRelationDatasets', (req, res, ctx) => {
    return res(
      ctx.data({
        queryRelationDatasets: {
          dataset: [getMockDatasetInfo('dataset_current_1')],
          downDatasets: _.map(_.range(5), (index) =>
            getMockDatasetInfo(`dataset_down_${index}`),
          ),
          upDatasets: _.map(_.range(5), (index) =>
            getMockDatasetInfo(`dataset_up_${index}`),
          ),
        },
      }),
    );
  }),
  graphql.query('queryRelation', (req, res, ctx) => {
    const list = _.map(_.range(3), (index) =>
      getMockDatasetInfo(`dataset_${index}`),
    );
    const mockSourceInfo = _.sample([
      // { type: ESourceType.TABLE, content: 'table_name' },
      // { type: ESourceType.SQL, content: "SELECT * FROM 'testTable'" },
      { type: ESourceType.FILE, content: 'file_id' },
    ]);

    const sourceInfo = getMockTableSourceInfo(
      mockSourceInfo.type,
      mockSourceInfo.content,
    );

    const columns = _.map(sourceInfo.columns, (col) => {
      const dataType = originDataType2DataTypeMap[col.originDataType];
      const type = [
        EColumnDataType.DECIMAL,
        // EColumnDataType.INT,
        EColumnDataType.DOUBLE,
      ].includes(dataType)
        ? EColumnType.MEASURE
        : EColumnType.DIMENSION;
      return {
        ...col,
        dataType,
        type,
        dimension: {
          name: getUniqId(),
          displayName: col.displayName,
          originDataType: col.originDataType,
          owner: {
            userId: getUniqId(),
          },
        },
      };
    });
    return res(
      ctx.data({
        queryRelation: {
          datasets: list,
          relations: getDatasetRelations(columns, false),
        },
      }),
    );
  }),
];
