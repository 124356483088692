import { EAxisOrient, IComboConfig } from './common/vizStyles/common';
import { IQueryResultData, TCellData } from '@/services/queryColumns/types';
import { IVizConfig } from './hooks/useGetOptions';
import { EColumnDataType, EDateGranularityType, EUnit } from '@/typings';
import { IFormatFormValues } from '@/services/metric/types';
import { IDerivateCalc } from '@/typings/analysisView';
import { TChartStyle } from '@/typings/styles';
import { EFormatValueType } from '@/common/domain/viz/format/formatCellData';

export enum EVizType {
  BAR_CHART = 'BAR_CHART',
  COLUMN_CHART = 'COLUMN_CHART',
  LINE_CHART = 'LINE_CHART',
  PIE_CHART = 'PIE_CHART',
  SCATTER_CHART = 'SCATTER_CHART',
  RADAR_CHART = 'RADAR_CHART',
  AREA_CHART = 'AREA_CHART',
  COMBO_CHART = 'COMBO_CHART',
}

export interface IContent<T extends IBaseField, U extends IBaseField> {
  id: string;
  width?: number;
  height?: number;
  type: EVizType;
  xAxis?: T;
  yAxis: U[];
  legend?: T;
  y2Axis?: U[];
  axisOrient: {
    [yId: string]: EAxisOrient;
  };
  formatConfig?: {
    [yId: string]: IFormatFormValues;
  };
  autoResize?: boolean;
  queryResultData: IQueryResultData | null;
  comboConfig?: IComboConfig;
  isMini?: boolean; // 是否是mini模式，用在首页
  shouldUpdate?: boolean;
  emptyText?: string; // 空态文案
  stylesConfig?: TChartStyle;
}

export interface IGetChartOptions {
  config: IVizConfig;
  queryResultData: IQueryResultData;
  formatterColumn: (
    columnData: IBaseField | null,
    value: TCellData,
    formatType: EFormatValueType,
  ) => TCellData;
  type: EVizType;
  isComboLine?: boolean;
  stylesConfig?: TChartStyle;
}

export interface IBaseField {
  id: string;
  dataType: EColumnDataType;
  displayName: string;
  unit?: EUnit;
  derivativeCalc?: IDerivateCalc;
  granularity?: EDateGranularityType;
}
