import {
  EUserCreationMode,
  EUserSource,
  IModuleAuthority,
  IStaticConfig,
} from '@/typings';
import CanLogo from '@/assets/img/can_logo2.png';
import saveThirdEnvToMemory from './saveThirdEnvToMemory';
import CANLoginPageLogo from '@/assets/icon/CAN.svg';
import t from '@/locales';
import { EQueryEngineType } from '@/typings/formula';
import { getResourceAuthority } from '../domain/authority/globalUserInfoHelper';
import { EResourceType } from '@/typings/authority';

// 租户信息
export const tenantInfo = window?.tenantInfo || {
  tenantId: '',
  tenantName: '',
  displayName: '',
  engineInfo: {
    queryEngineType: EQueryEngineType.AIR,
    displayName: '',
    name: '',
  },
  logo: '', // 资源地址};
};

export const customTheme = (() => {
  if (window?.customTheme) {
    if (
      window.customTheme.logo &&
      window.customTheme.backgroundColor &&
      window.customTheme.theme
    ) {
      document.title = t.common.document.title;
      return {
        ...window.customTheme,
        loginPage: window.customTheme.loginPage
          ? window.customTheme.loginPage
          : {
              loginLogo: CANLoginPageLogo,
            },
      };
    }
  }

  return {
    logo: CanLogo, // 资源地址
    homeSearchBgColor: 'linear-gradient(180deg, #D4E7FD 0%, #FFF 100%)',
    backgroundColor: '#111827',
    theme: {
      default: {
        backgroundColor: 'transparent',
        color: 'rgba(255, 255, 255, 0.7)',
      },
      active: {
        backgroundColor: '#0b4385',
        color: '#fff',
      },
      hover: {
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
        color: 'rgba(255, 255, 255, 0.7)',
      },
    },
    loginPage: {
      loginLogo: CANLoginPageLogo,
    },
  };
})();
// 用户信息
export const userInfo = window.userInfo || {
  userId: '', // 用户 ID
  account: '', // 账号
  nickname: '', // 昵称
  photo: '', // 头像地址
  userSource: EUserSource.ALOUDATA,
  allAuthorityResource: [],
  ban: false,
  creationMode: EUserCreationMode.MANUAL,
};
export const moduleSwitch = window.moduleSwitch || {};

// 被用于第三方系统的token信息
export const thirdEnvInfo: {
  token?: string | null;
  hideNav?: boolean;
} = {
  token: '',
  hideNav: false,
};
saveThirdEnvToMemory(thirdEnvInfo);
// 是否展示水印
export const enableWatermark = window.appSettings?.enableWatermark || false;

// 是否启用编码
let enableCode = window.appSettings?.enableCode || false;
/**
 * 由于在管理页面用户可以切换是否启用编码，所以存在不刷新页面的情况下修改 enableCode 的情况
 * @returns 是否启用编码
 */
export function getEnableCode() {
  return enableCode;
}

export function setEnableCode(newEnableCode: boolean) {
  enableCode = newEnableCode;
}

// 引擎信息
export const engineInfo = tenantInfo.engineInfo;

// 日志路径
export const loggerPath = window.loggerPath;

export function getFeVersion() {
  const publicPath = (window as unknown as { publicPath: string })
    .publicPath as string;
  if (publicPath) {
    const pathArr = publicPath.split('/');
    const POSITION_FROM_END = 2;
    const version = pathArr[pathArr.length - POSITION_FROM_END];
    return version;
  }
  return '';
}

export function clearVMInfo() {
  delete window.tenantInfo;
  delete window.userInfo;
  delete window.appSettings;
  delete window.staticConfig;
}

export const isLocalhostEnv = window.location.hostname === 'localhost';

// 需要错误上报的地址
export const anymetricsHosts = [
  'demo.can.aloudata.com',
  'release.can.aloudata.work',
];

// 忽略上报的地址
export const ignoreHosts = [
  'dev.anymetrics.aloudata.work',
  'test.anymetrics.aloudata.work',
];

export const enableCustomDateRangePeriod =
  window.enableCustomDateRangePeriod || false;

export const sentryDSN =
  'https://de4aef1368e941a18d67bba074e9e2de@sentry.joinsight.com/11';

export const functionShowControl = window.functionShowControl || {};

export const staticConfig: Partial<IStaticConfig> = window.staticConfig || {};

export const hasAccelerationAuthority = (moduleAuth: IModuleAuthority) => {
  return (
    !moduleAuth.disableAcceleration &&
    getResourceAuthority(EResourceType.TENANT)?.canCreateResources?.some(
      (item) => {
        return [EResourceType.RP_TASK, EResourceType.RESULT_PLAN].includes(
          item,
        );
      },
    )
  );
};

export const userTenantAuthority = userInfo?.allAuthorityResource?.find(
  (item) => item.resourceType === EResourceType.TENANT,
);
