import RcTree from 'rc-tree';
import ResizeObserver from 'rc-resize-observer';
import { ReactComponent as SearchIcon } from '@/assets/icon/Search-light-line.svg';
import styles from './index.less';
import _ from 'lodash';
import ColumnDataTypeIcon from '@/common/ui/ColumnDataTypeIcon';
import {
  CSSProperties,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Checkbox,
  Empty,
  IconButton,
  Input,
  InputRef,
  ScrollArea,
  Spin,
  Tree,
  message,
} from '@aloudata/aloudata-design';
import { FixedSizeList as List } from 'react-window';
import t from '@/locales';
import classnames from 'classnames';
import { TMetric } from '@/typings/metric';
import { ReactComponent as FavoriteFillIcon } from '@/assets/icon/Star-fill.svg';
import { ReactComponent as FavoriteLineIcon } from '@/assets/icon/Star-light-line.svg';
import { useCancelCollectMetric, useCollectMetric } from '@/services/metric';
import { PickerTooltip } from './Tooltip';
import { filterByKeyword } from '@/common/utils';
import { TAddMetricFn } from '@/pages/Workbook/Detail/components/ConfigArea/getSidebarConfig';
import { DND_TYPE_ADD_METRIC } from '@/pages/Workbook/Detail/common/constants';
import DragItem from '@/pages/Workbook/Detail/components/DndComp/DragItem';
import { ICategory } from '@/typings/category';
import { buildCategoryTree } from '@/pages/Manage/CategoryManage/categoryHelper';
import { fillMetricListIntoTreeData, getSelectedItemPath } from './helper';
import {
  EDisplayType,
  IDataItem,
  IMetricPickerRef,
  ISelectedMetricTreeItem,
  ITreeNode,
} from './types';
import { excludeEmptyCategory } from '@/common/domain/dimension/DimensionPicker/helper';
import { originDataType2DataTypeMap } from '@/common/domain/Formula/constant';
import MetricIcon from '../MetricIcon';
import { getEnableCode } from '@/common/globalInfo/appConfig';

const enableCode = getEnableCode();

function PureMetricList(
  props: IPureMetricListProps,
  ref: React.ForwardedRef<IMetricPickerRef>,
) {
  const {
    draggable = false,
    onClick,
    canFav,
    canEdit,
    showFavFolder,
    height,
    metrics: metricList,
    categories,
    filter,
    onChangeFav,
    getDisabledMap,
    loading = false,
    openInDropdown,
    hideEmptyCategory,
    size,
    isInPopup,
    selected,
    onSelect,
    showAllCategory,
    showSearch = true,
    showUnPublished = false,
    authority = true,
    showDataType = true,
    checkedKeys,
    onCheck,
    className,
    renderCategoryNode,
  } = props;
  const listRef = useRef<List>(null);
  const [listSize, setListSize] = useState<{
    width: number;
    height: number;
  }>({
    width: 0,
    height: 0,
  });
  const [keyword, setKeyword] = useState('');
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
  const inputRef = useRef<InputRef | null>(null);
  const ICON_SIZE_SMALL = 16;
  const ICON_SIZE_MIDDLE = 20;
  const iconSize = size === 'small' ? ICON_SIZE_SMALL : ICON_SIZE_MIDDLE;
  const treeRef = useRef<RcTree>(null);

  const checkable = typeof onCheck === 'function';

  useEffect(() => {
    if (openInDropdown) {
      setKeyword('');
      setExpandedKeys([]);
      listRef.current?.scrollTo(0);
    }
  }, [openInDropdown]);
  useEffect(() => {
    const input = inputRef.current;
    if (input && openInDropdown) {
      // 因为 autoFocus 主要用在下拉弹窗这种场景，下拉弹窗出现时渲染有个延迟，不设置延时的话，focus 不会生效
      setTimeout(() => {
        input.focus();
      }, 0);
    }
  }, [openInDropdown]);
  // 在 Select 的 renderDropdown 中，openInDropdown 会一直为 true，因此需要提供 ref 便于手动操作
  useImperativeHandle(ref, () => ({
    reset: () => {
      setKeyword('');
      setExpandedKeys([]);
    },
    focus: () => {
      const input = inputRef.current;
      if (input) {
        // 因为 autoFocus 主要用在下拉弹窗这种场景，下拉弹窗出现时渲染有个延迟，不设置延时的话，focus 不会生效
        setTimeout(() => {
          input.focus();
        }, 0);
      }
    },
  }));

  const onClickItem = useCallback(
    (metric: TMetric) => {
      onClick?.(metric);
    },
    [onClick],
  );

  const disabledMap = useMemo(() => {
    return typeof getDisabledMap === 'function'
      ? getDisabledMap(metricList)
      : {};
  }, [getDisabledMap, metricList]);

  const filteredList = useMemo(() => {
    let list = metricList;
    list = filterByKeyword<TMetric>(
      list,
      [
        (item) => item.name,
        (item) => item.metricName,
        (item) => (enableCode ? item.metricCode || '' : ''),
      ],
      keyword,
    );
    if (typeof filter === 'function') {
      list = _.filter(list, filter) || [];
    }

    if (!showUnPublished) {
      // 过滤未发布的指标
      list = _.filter(list, (item) => item.isPublished !== false) || [];
    }

    if (canEdit === true) {
      // 过滤没有编辑权限的指标
      list = _.filter(list, (item) => item.authority?.canEdit === true) || [];
    }
    let listWithDisabled = _.map(list, (metric) => {
      let disabledReason = '';
      // 先禁用没有权限的指标
      if (authority && metric.authority?.canUsage === false) {
        disabledReason = t.workbook.workbook.picker.metric.noPermission;
      } else {
        disabledReason = disabledMap[metric.code] || '';
      }
      return {
        metric,
        disabled: !!disabledReason,
        disabledReason,
      };
    });
    // 根据可用性排序，可用的在前面
    listWithDisabled = _.sortBy(listWithDisabled, (item) => item.disabled);
    return listWithDisabled;
  }, [
    metricList,
    keyword,
    filter,
    showUnPublished,
    canEdit,
    authority,
    disabledMap,
  ]);

  const treeData = useMemo(() => {
    const categoryTree = buildCategoryTree(categories);
    if (!_.size(categoryTree)) {
      return [];
    }
    let tree = fillMetricListIntoTreeData(
      filteredList,
      categoryTree,
      iconSize,
      showFavFolder,
      showAllCategory,
      _.keys(disabledMap),
    );
    if (hideEmptyCategory) {
      tree = excludeEmptyCategory(tree);
    }
    return tree;
  }, [
    categories,
    filteredList,
    showFavFolder,
    hideEmptyCategory,
    iconSize,
    showAllCategory,
    disabledMap,
  ]);

  const { cancelCollectMetric } = useCancelCollectMetric();
  const { collectMetric } = useCollectMetric();
  const onCollectMetric = useCallback(
    async (collect: boolean, code: string) => {
      if (collect) {
        // 取消收藏
        await cancelCollectMetric({ code });
        message.success(t.metric.metric.list.menu.cancelCollect.success);
      } else {
        // 收藏
        await collectMetric({ code });
        message.success(t.metric.metric.list.menu.collect.success);
      }
    },
    [collectMetric, cancelCollectMetric],
  );

  const onMetricCollectChange = useCallback(
    (metric: TMetric) => {
      if (metric.collect === undefined) {
        return;
      }
      onCollectMetric(metric.collect, metric.code).then(() => {
        if (typeof onChangeFav === 'function') {
          onChangeFav(metric, !metric.collect);
        }
      });
    },
    [onChangeFav, onCollectMetric],
  );

  const renderItem = useCallback(
    ({
      item,
      style = {},
      classNames,
      showCheckbox = false,
    }: {
      item: IDataItem;
      style?: CSSProperties;
      classNames?: string;
      showCheckbox?: boolean;
    }) => {
      const { metric, disabled, disabledReason } = item;
      return (
        <DragItem
          id={metric.code}
          key={metric.code}
          data={metric}
          dndType={disabled ? '' : DND_TYPE_ADD_METRIC}
          draggable={draggable}
        >
          <PickerTooltip metric={metric} reason={disabledReason}>
            <div
              className={classnames(
                styles.metricItem,
                {
                  [styles.draggable]: draggable !== false,
                  [styles.disabled]: disabled,
                  [styles.small]: size === 'small',
                },
                classNames || '',
              )}
              key={metric.code}
              style={style}
            >
              {showCheckbox && (
                <div className={styles.checkbox}>
                  <Checkbox
                    disabled={disabledMap[metric.code] !== undefined}
                    checked={checkedKeys?.includes(metric.code)}
                    size={size}
                  />
                </div>
              )}
              <div className={styles.dataType}>
                {showDataType ? (
                  <ColumnDataTypeIcon
                    dataType={originDataType2DataTypeMap[metric.originDataType]}
                    size={iconSize}
                  />
                ) : (
                  <MetricIcon type={metric.type} size={iconSize} />
                )}
              </div>
              <div className={styles.nameWrapper}>
                <div className={styles.name}>
                  {metric.name || metric.metricName}
                </div>
              </div>
              {canFav && (
                <IconButton
                  className={classnames(styles.collection, {
                    [styles.hasCollection]: metric.collect,
                  })}
                  onClick={() => {
                    onMetricCollectChange(metric);
                  }}
                  icon={
                    metric.collect ? (
                      <FavoriteFillIcon color="#FFB01F" size={iconSize} />
                    ) : (
                      <FavoriteLineIcon size={iconSize} />
                    )
                  }
                ></IconButton>
              )}
            </div>
          </PickerTooltip>
        </DragItem>
      );
    },
    [
      canFav,
      draggable,
      onMetricCollectChange,
      iconSize,
      size,
      showDataType,
      checkedKeys,
      disabledMap,
    ],
  );
  const renderListItem = useCallback(
    ({ index, style }: { index: number; style: CSSProperties }) => {
      const item = filteredList[index];

      const selectedClassName =
        selected && selected.key === item.metric.code ? styles.selected : '';
      const isChecked = checkedKeys?.includes(item.metric.code);

      return (
        <div
          onClick={() => {
            if (disabledMap[item.metric.code] !== undefined) {
              return;
            }
            if (checkable) {
              if (isChecked) {
                onCheck?.(
                  checkedKeys?.filter((key) => key !== item.metric.code) || [],
                );
              } else {
                onCheck?.([...(checkedKeys || []), item.metric.code]);
              }
              return;
            }
            onSelect?.({
              key: item.metric.code,
              isCategory: false,
            });
            onClick?.(item.metric);
          }}
        >
          {renderItem({
            item,
            style,
            classNames: selectedClassName,
            showCheckbox: checkable,
          })}
        </div>
      );
    },
    [
      filteredList,
      renderItem,
      selected,
      onSelect,
      onClick,
      checkable,
      checkedKeys,
      onCheck,
      disabledMap,
    ],
  );
  const displayType = keyword ? EDisplayType.LIST : EDisplayType.TREE;
  const emptyNode = useMemo(() => {
    if (loading) {
      return null;
    }
    if (displayType === EDisplayType.LIST && filteredList.length !== 0) {
      return null;
    }
    if (displayType === EDisplayType.TREE && treeData.length !== 0) {
      return null;
    }
    return <Empty size="small" title={t.workbook.workbook.picker.notFound} />;
  }, [filteredList, treeData, loading, displayType]);

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const scrollTop = (e.target as HTMLDivElement).scrollTop;
    listRef.current?.scrollTo(scrollTop);
  };
  const onExpandCategory = useCallback((keys: Array<React.Key>) => {
    setExpandedKeys(keys as string[]);
  }, []);
  const renderTreeNode = useCallback(
    (treeNode: ITreeNode) => {
      if (treeNode.isCategory) {
        return renderCategoryNode?.(treeNode) || treeNode.title;
      }
      return renderItem({ item: treeNode.data as IDataItem });
    },
    [renderItem, renderCategoryNode],
  );

  // 当 selected 改变时，展开到选中的项的所有父类目
  useEffect(() => {
    if (!selected || !treeData || !treeData.length) {
      return;
    }
    const path = getSelectedItemPath(treeData, selected.key);
    setExpandedKeys((prev) => {
      return _.uniq([...prev, ...path]);
    });
    // 由于树组件的滚动需要在下一帧执行，所以使用setTimeout
    setTimeout(() => {
      treeRef.current?.scrollTo({
        key: selected.key,
      });
    }, 0);
  }, [selected, treeData]);

  let content = (
    <Tree.DirectoryTree<ITreeNode>
      ref={treeRef}
      checkable={checkable}
      checkedKeys={checkedKeys}
      onCheck={(newCheckedKeys, e) => {
        const { checkedNodes } = e;
        const leafKeys = checkedNodes
          .filter((node) => !node.children)
          .map((node) => node.key);
        onCheck?.(leafKeys as string[]);
      }}
      size={size}
      treeData={treeData}
      selectedKeys={selected ? [selected.key] : []}
      onSelect={(selectedKeys, info) => {
        if (checkable) {
          return;
        }
        // 组件内部合并了 DataNode 和 ITreeNode 的字段
        const { node } = info;
        const treeNode = node as unknown as ITreeNode;
        const selectedData: ISelectedMetricTreeItem = {
          key: treeNode.key,
          isCategory: !!treeNode.isCategory,
        };
        onSelect?.(selectedData);
      }}
      onClick={(e, data) => {
        if (checkable) {
          return;
        }
        const treeItemData = data as unknown as ITreeNode;
        if (treeItemData.isCategory === false) {
          const dataItem = treeItemData.data as IDataItem;
          if (!dataItem.disabled) {
            onClickItem(dataItem.metric);
          }
        }
      }}
      expandedKeys={expandedKeys}
      onExpand={onExpandCategory}
      height={listSize.height}
      titleRender={renderTreeNode}
      selectable={!!selected}
      showIcon
    />
  );
  if (emptyNode) {
    content = emptyNode;
  } else if (displayType === EDisplayType.LIST) {
    const SMALL_ITEM_SIZE = 24;
    const MIDDLE_ITEM_SIZE = 32;
    const itemSize = size === 'small' ? SMALL_ITEM_SIZE : MIDDLE_ITEM_SIZE;
    content = (
      <div className={styles.list}>
        <ScrollArea onViewportScroll={handleScroll}>
          <List
            height={listSize.height}
            itemCount={filteredList.length}
            itemSize={itemSize}
            width={listSize.width}
            ref={listRef}
            style={{ overflow: 'visible' }}
          >
            {renderListItem}
          </List>
        </ScrollArea>
      </div>
    );
  }

  return (
    <div
      className={classnames(className, styles.container, {
        [styles.popup]: isInPopup,
      })}
      style={{ height }}
    >
      <Spin spinning={loading} wrapperClassName={styles.spinWrapper}>
        <>
          {showSearch && (
            <div
              className={classnames(styles.search, {
                [styles.small]: size === 'small',
              })}
            >
              <Input
                bordered={!isInPopup}
                value={keyword}
                size={size}
                onChange={(e) => {
                  setKeyword(e.target.value);
                }}
                allowClear
                placeholder={t.common.list.searchMetric}
                prefix={<SearchIcon size={iconSize} />}
                ref={(elem) => (inputRef.current = elem)}
              />
            </div>
          )}
          <div className={styles.body}>
            <ResizeObserver
              onResize={(sizeInfo) => {
                setListSize((prev) => {
                  if (
                    prev.width === sizeInfo.offsetWidth &&
                    prev.height === sizeInfo.offsetHeight
                  ) {
                    return prev;
                  }
                  return {
                    width: sizeInfo.offsetWidth,
                    height: sizeInfo.offsetHeight,
                  };
                });
              }}
            >
              <div className={styles.content}>{content}</div>
            </ResizeObserver>
          </div>
        </>
      </Spin>
    </div>
  );
}
export default forwardRef(PureMetricList);
export interface IPureMetricListProps {
  className?: string;
  metrics: TMetric[];
  categories: ICategory[];
  getDisabledMap?: (metrics: TMetric[]) => IDisabledMap;
  draggable?: boolean; // 是否支持拖拽
  onClick?: TAddMetricFn;
  filter?: (metric: TMetric) => boolean;
  canFav?: boolean;
  canEdit?: boolean; // 是否有编辑权限
  showFavFolder?: boolean; // 是否展示收藏夹
  onChangeFav?: (metric: TMetric, isFav: boolean) => void;
  height?: number;
  loading?: boolean;
  // TODO: 后续删掉这个属性
  openInDropdown?: boolean; // 在下拉弹窗中渲染内容时，当前是否打开状态
  hideEmptyCategory?: boolean; // 隐藏空分类
  size?: 'small';
  isInPopup?: boolean; // 是否用在弹窗中
  // 选中项时回调，可以是指标或类目
  onSelect?: (item: ISelectedMetricTreeItem) => void;
  // 已选中的项
  selected?: ISelectedMetricTreeItem;
  checkedKeys?: string[];
  onCheck?: (checkedKeys: string[]) => void;
  showAllCategory?: boolean; // 是否展示“全部指标”类目
  showSearch?: boolean; // 是否展示搜索框
  showUnPublished?: boolean; // 是否展示未发布的指标
  showDataType?: boolean; // 是否展示数据类型
  authority?: boolean; // 是否检查权限 default：true
  renderCategoryNode?: (node: ITreeNode) => React.ReactNode;
}
type disabledReason = string;
export interface IDisabledMap {
  [code: string]: disabledReason;
}
