import t from '@/locales';
import {
  EOriginDataType,
  EQueryEngineType,
  IFunctionOperator,
} from '@/typings/formula';
import { ECheckResultType, TCheckResult } from '../function';
import checkCast from './cast';
import checkCastFn from './Date';
import checkIf from './if';
import checkIn from './in';
import checkLag from './lag';
import checkNth from './nth';
import checkSubtotal from './subtotal';
const specialTreatment = (params: {
  op: string;
  args: EOriginDataType[];
  ast: IFunctionOperator;
  queryEngineType: EQueryEngineType;
}): TCheckResult => {
  const { op, args } = params;
  switch (op.toUpperCase()) {
    case 'CAST':
      return checkCast(params);
    case 'IF':
      return checkIf(params);
    case 'IN':
    case 'NOTIN':
      return checkIn(params);
    case 'SUBTOTAL':
      return checkSubtotal(params);
    case 'POWERFIX':
    case 'POWERADD':
    case 'POWERSUB':
      if (args.length > 0) {
        return {
          type: ECheckResultType.ORIGIN_DATA_TYPE,
          originDataType: args[0],
        };
      } else {
        return {
          type: ECheckResultType.ORIGIN_DATA_TYPE,
          originDataType: EOriginDataType.VARCHAR,
        };
      }
    case 'LEAD':
    case 'LAG':
      return checkLag(params);
    case 'NTH':
      return checkNth(params);
    case 'DATE':
    case 'DATETIME':
    case 'JSON':
    case 'DECIMAL':
    case 'TEXT':
    case 'INT':
    case 'DOUBLE':
    case 'BOOL':
      return checkCastFn(params);
    default:
      return {
        type: ECheckResultType.ERROR,
        errorMsg: `${op}${t.formula.derivate.error.unknownError}`,
      };
  }
};
export default specialTreatment;
