import { graphql } from 'msw';
import {
  EApprovalOperationType,
  EApprovalProcessStatus,
  EApprovalProcessType,
  EApprovalStatus,
  EApprovalUserResourceType,
  IApprovalProcessConfig,
  IApprovalSettingItem,
} from '../../src/typings/approve';
import _ from 'lodash';
import { getUniqId } from '../../src/common/utils';
import { ISelectDataInputOption } from '../../src/typings/property';
import { EResourceType, EUserRole } from '../../src/typings/authority';
import {
  dimensionApproveNodeDisplaynameMap,
  metricApproveNodeDisplaynameMap,
} from '../../src/common/domain/approval/ApprovalDetail/constant';

export const approval = [
  // 获取列表
  graphql.query('queryApprovalSettingList', (req, res, ctx) => {
    const resourceType = req.variables.approvalResourceType;
    return res(
      ctx.data({
        queryApprovalSettingList: Object.values(EApprovalOperationType).map(
          (type, index): IApprovalSettingItem => ({
            approveCatalogId: index,
            approveOperationType: type,
            approvalResourceType: resourceType,
            displayname:
              resourceType === EResourceType.METRIC
                ? metricApproveNodeDisplaynameMap[type]
                : dimensionApproveNodeDisplaynameMap[type],
            description: '这是一个描述',
            status: _.sample([EApprovalStatus.CLOSE, EApprovalStatus.OPEN]),
          }),
        ),
      }),
    );
  }),

  // 获取列表
  graphql.query('queryApprovalDetailById', (req, res, ctx) => {
    const selectInputItem = Array.from({ length: 6 })
      .fill(0)
      .map((v, i) => ({
        value: 'level' + i,
        systemValue: 'T' + i,
      }));

    return res(
      ctx.data({
        queryApprovalDetailById: [1, 2, 3, 4].map((key) => ({
          approvalId: getUniqId(),
          sort: key,
          displayName: '审批流' + key,
          approvalPropertyConfig: _.sample([
            {
              dataInput: {
                options: _.sampleSize(selectInputItem, 3), // 用户选中的
                selectInputItem: selectInputItem,
              },
            },
            null,
          ]) as
            | {
                dataInput: {
                  options: ISelectDataInputOption[]; // 用户选中的
                  selectInputItem: ISelectDataInputOption[]; // 全部的可选项
                };
              }[]
            | null,
          approvalProcessConfig: Array.from({ length: 4 }).map(
            (v, i): IApprovalProcessConfig => ({
              tempId: getUniqId(),
              approvalProcessId: getUniqId(),
              approvalId: getUniqId(),
              sort: i,
              approvalUserResourceId: [],
              approvalUserResourceType: _.sample([
                EApprovalUserResourceType.GROUP,
                EApprovalUserResourceType.USER,
                EApprovalUserResourceType.ROLE,
              ]) as EApprovalUserResourceType,
              approvalProcessType: _.sample([
                EApprovalProcessType.AND,
                EApprovalProcessType.OR,
              ]),
            }),
          ),
          approvalUserResourceType: EApprovalUserResourceType.ALL,
        })),
      }),
    );
  }),

  graphql.mutation('updateApprovalCatalogStatus', (req, res, ctx) => {
    return res(
      ctx.data({
        updateApprovalCatalogStatus: true,
      }),
    );
  }),

  // createOrUpdateApproval,
  graphql.mutation('createOrUpdateApproval', (req, res, ctx) => {
    return res(
      ctx.data({
        createOrUpdateApproval: true,
      }),
    );
  }),
  // 获取审批中心-我的任务列表
  graphql.query('queryApprovalRequiredLogList', (req, res, ctx) => {
    const isFinished = req.variables.isFinalStatus;

    return res(
      ctx.data({
        queryApprovalRequiredLogList: Array.from({ length: 10 }).map(
          (v, i) => ({
            approvalId: 'approval_123' + i,
            approvalLogId: 'approvalLog_456' + i,
            approvalProcessId: 'approvalProcess_789' + i,
            approvalSnapshotId: 'approvalSnapshot_abc' + i,
            resourceId: 'resource_def' + i,
            resourceName: '资源名称' + i,
            canProcess: Math.random() > 0.5,

            approvalResourceType: _.sample([
              EResourceType.METRIC,
              EResourceType.DIMENSION,
            ]),
            approvalOperationType: EApprovalOperationType.CREATE,
            message: '请审批此资源的创建申请',
            approvalOperator: {
              userId: getUniqId(),
              account: 'account' + i,
              nickname: '审批人' + i,
            },
            applicantOperator: {
              userId: getUniqId(),
              account: 'account' + i,
              nickname: '申请人' + i,
            },
            createTime: 1621234567890,
            approvalResourceTargetResult: EUserRole.ANALYST,
            approvalFinalProcessStatus: _.sample([
              EApprovalProcessStatus.PENDING,
              EApprovalProcessStatus.PASS,
              EApprovalProcessStatus.REFUSE,
            ]),
            processNodes: Array.from({ length: 5 }).map((v, i) => ({
              approvalProcessId: getUniqId(),
              approvalId: getUniqId(),
              approvalUserResourceId: 'user_00' + i,
              approvalUserResourceType: EApprovalUserResourceType.USER_GROUP,
              approvalProcessType: EApprovalProcessType.AND,
              approvalUserResourceNames: ['审批人1', '审批人2'],
              users: [
                {
                  userId: getUniqId(),
                  account: 'account1' + i,
                  nickname: '审批人1',
                },
                {
                  userId: getUniqId(),
                  account: 'account2' + i,
                  nickname: '审批人2',
                },
              ],
              processStatus: EApprovalProcessStatus.PENDING,
              processTime: 0,
              approvalMessageLogs: [
                {
                  approvalLogId: 'approvalLog_456',
                  approvalOperator: {
                    userId: getUniqId(),
                    account: 'account1' + i,
                    nickname: '审批人1',
                  },
                  approvalProcessId: 'approvalProcess_789',
                  approvalUserResourceId: ['user_003', 'user_004'],
                  approvalUserResourceType: EApprovalUserResourceType.USER,
                  message: '同意此次审批',
                  approvalTime: 1621234567890,
                  approvalProcessStatus: EApprovalProcessStatus.PENDING,
                },
                {
                  approvalLogId: 'approvalLog_456',
                  approvalOperator: {
                    userId: getUniqId(),
                    account: 'account1' + i,
                    nickname: '审批人2',
                  },
                  approvalProcessId: 'approvalProcess_789',
                  approvalUserResourceId: ['user_003', 'user_004'],
                  approvalUserResourceType: EApprovalUserResourceType.USER,
                  message: '同意此次审批',
                  approvalTime: 1621234567890,
                  approvalProcessStatus: EApprovalProcessStatus.PASS,
                },
              ],
            })),
          }),
        ),
      }),
    );
  }),

  // 获取审批中心-我的申请列表
  graphql.query('queryApprovalApplicantLogList', (req, res, ctx) => {
    const isFinished = req.variables.isFinalStatus;
    return res(
      ctx.data({
        queryApprovalApplicantLogList: Array.from({ length: 10 }).map(
          (v, i) => ({
            approvalId: 'approval_123' + i,
            approvalLogId: 'approvalLog_456' + i,
            approvalProcessId: 'approvalProcess_789' + i,
            approvalSnapshotId: 'approvalSnapshot_abc' + i,
            resourceId: 'resource_def' + i,
            resourceName: '资源名称' + i,
            approvalResourceType: _.sample([
              EResourceType.METRIC,
              EResourceType.DIMENSION,
            ]),
            approvalOperationType: EApprovalOperationType.CREATE,
            message: '请审批此资源的创建申请',
            approvalOperator: {
              userId: getUniqId(),
              account: 'account' + i,
              nickname: '审批人' + i,
            },
            applicantOperator: {
              userId: getUniqId(),
              account: 'account' + i,
              nickname: '申请人' + i,
            },
            createTime: 1621234567890,
            canProcess: Math.random() > 0.5,

            approvalResourceTargetResult: EUserRole.ANALYST,
            approvalFinalProcessStatus: _.sample([
              EApprovalProcessStatus.PENDING,
              EApprovalProcessStatus.PASS,
              EApprovalProcessStatus.REFUSE,
            ]),
            processNodes: Array.from({ length: 5 }).map((v, i) => ({
              approvalProcessId: getUniqId(),
              approvalId: getUniqId(),
              approvalUserResourceId: 'user_00' + i,
              approvalUserResourceType: EApprovalUserResourceType.USER_GROUP,
              approvalProcessType: EApprovalProcessType.AND,
              approvalUserResourceNames: ['审批人1', '审批人2'],
              users: [
                {
                  userId: getUniqId(),
                  account: 'account1' + i,
                  nickname: '审批人1',
                },
                {
                  userId: getUniqId(),
                  account: 'account2' + i,
                  nickname: '审批人2',
                },
              ],
              processStatus: EApprovalProcessStatus.PENDING,
              processTime: 0,
              approvalMessageLogs: [
                {
                  approvalLogId: 'approvalLog_456',
                  approvalOperator: {
                    userId: getUniqId(),
                    account: 'account1' + i,
                    nickname: '审批人1',
                  },
                  approvalProcessId: 'approvalProcess_789',
                  approvalUserResourceId: ['user_003', 'user_004'],
                  approvalUserResourceType: EApprovalUserResourceType.USER,
                  message: '同意此次审批',
                  approvalTime: 1621234567890,
                  approvalProcessStatus: EApprovalProcessStatus.PENDING,
                },
                {
                  approvalLogId: 'approvalLog_456',
                  approvalOperator: {
                    userId: getUniqId(),
                    account: 'account1' + i,
                    nickname: '审批人2',
                  },
                  approvalProcessId: 'approvalProcess_789',
                  approvalUserResourceId: ['user_003', 'user_004'],
                  approvalUserResourceType: EApprovalUserResourceType.USER,
                  message: '同意此次审批',
                  approvalTime: 1621234567890,
                  approvalProcessStatus: EApprovalProcessStatus.PASS,
                },
              ],
            })),
          }),
        ),
      }),
    );
  }),

  // queryApprovalProcessLogDetail
  graphql.query('queryApprovalProcessLogDetail', (req, res, ctx) => {
    return res(
      ctx.data({
        queryApprovalProcessLogDetail: {
          approvalId: 'approval_123',
          approvalLogId: 'approvalLog_456',
          approvalProcessId: 'approvalProcess_789',
          approvalSnapshotId: 'approvalSnapshot_abc',
          resourceId: 'resource_def',
          resourceName: '资源名称',
          approvalResourceType: EResourceType.METRIC,
          approvalOperationType: EApprovalOperationType.CREATE,
          message: '请审批此资源的创建申请',
          approvalOperator: {
            id: 'user_001',
            username: 'approver',
            nickname: '审批者',
            email: 'approver@example.com',
            role: EUserRole.OWNER,
          },
          applicantOperator: {
            id: 'user_002',
            username: 'applicant',
            nickname: '申请者',
            email: 'applicant@example.com',
            role: EUserRole.ANALYST,
          },
          createTime: 1621234567890,
          canProcess: Math.random() > 0.5,
          approvalResourceTargetResult: EUserRole.ANALYST,
          approvalFinalProcessStatus: EApprovalProcessStatus.PENDING,
          processNodes: [
            {
              approvalProcessId: 'approvalProcess_789',
              approvalId: 'approval_123',
              approvalUserResourceId: 'userGroup_001',
              approvalUserResourceType: EApprovalUserResourceType.USER_GROUP,
              approvalProcessType: EApprovalProcessType.AND,
              approvalUserResourceNames: ['用户组1', '用户组2'],
              users: [
                {
                  id: 'user_003',
                  username: 'approver1',
                  nickname: '审批者1',
                  email: 'approver1@example.com',
                  role: EUserRole.OWNER,
                },
                {
                  id: 'user_004',
                  username: 'approver2',
                  nickname: '审批者2',
                  email: 'approver2@example.com',
                  role: EUserRole.OWNER,
                },
              ],
              processStatus: EApprovalProcessStatus.PENDING,
              processTime: 0,
              approvalMessageLogs: [
                {
                  approvalLogId: 'approvalLog_456',
                  approvalOperator: {
                    userId: getUniqId(),
                    account: 'account1',
                    nickname: '审批人1',
                  },
                  approvalProcessId: 'approvalProcess_789',
                  approvalUserResourceId: ['user_003', 'user_004'],
                  approvalUserResourceType: EApprovalUserResourceType.USER,
                  realApprovalOperator: {
                    userId: getUniqId(),
                    account: 'account1',
                    nickname: '审批人1',
                  },
                  message: '同意此次审批',
                  approvalTime: 1621234567890,
                  approvalProcessStatus: EApprovalProcessStatus.PASS,
                },
                {
                  approvalLogId: 'approvalLog_456',
                  approvalOperator: {
                    userId: getUniqId(),
                    account: 'account1',
                    nickname: '审批人2',
                  },
                  approvalProcessId: 'approvalProcess_789',
                  approvalUserResourceId: ['user_003', 'user_004'],
                  approvalUserResourceType: EApprovalUserResourceType.USER,
                  realApprovalOperator: {
                    userId: getUniqId(),
                    account: 'account1',
                    nickname: '审批人2',
                  },
                  message: '同意此次审批',
                  approvalTime: 1621234567890,
                  approvalProcessStatus: EApprovalProcessStatus.PENDING,
                },
              ],
            },
          ],
        },
      }),
    );
  }),
  // 拒绝、通过审批
  graphql.mutation('processApproval', (req, res, ctx) => {
    return res(ctx.data({ processApproval: true }));
  }),
  // 撤销审批
  graphql.mutation('revokeApproval', (req, res, ctx) => {
    return res(ctx.data({ revokeApproval: true }));
  }),

  graphql.query(
    'queryApprovalProcessLogDetailByResourceId',
    (req, res, ctx) => {
      return res(
        ctx.data({
          queryApprovalProcessLogDetailByResourceId: {
            approvalId: 'approval_123',
            approvalLogId: 'approvalLog_456',
            approvalProcessId: 'approvalProcess_789',
            approvalSnapshotId: 'approvalSnapshot_abc',
            resourceId: 'resource_def',
            resourceName: '资源名称',
            approvalResourceType: EResourceType.METRIC,
            approvalOperationType: EApprovalOperationType.CREATE,
            message: '请审批此资源的创建申请',
            approvalOperator: {
              id: 'user_001',
              username: 'approver',
              nickname: '审批者',
              email: 'approver@example.com',
              role: EUserRole.OWNER,
            },
            applicantOperator: {
              id: 'user_002',
              username: 'applicant',
              nickname: '申请者',
              email: 'applicant@example.com',
              role: EUserRole.ANALYST,
            },
            createTime: 1621234567890,
            approvalResourceTargetResult: EUserRole.ANALYST,
            approvalFinalProcessStatus: EApprovalProcessStatus.PENDING,
            processNodes: [
              {
                approvalProcessId: 'approvalProcess_789',
                approvalId: 'approval_123',
                approvalUserResourceId: 'userGroup_001',
                approvalUserResourceType: EApprovalUserResourceType.USER_GROUP,
                approvalProcessType: EApprovalProcessType.AND,
                approvalUserResourceNames: ['用户组1', '用户组2'],
                users: [
                  {
                    id: 'user_003',
                    username: 'approver1',
                    nickname: '审批者1',
                    email: 'approver1@example.com',
                    role: EUserRole.OWNER,
                  },
                  {
                    id: 'user_004',
                    username: 'approver2',
                    nickname: '审批者2',
                    email: 'approver2@example.com',
                    role: EUserRole.OWNER,
                  },
                ],
                processStatus: EApprovalProcessStatus.PENDING,
                processTime: 0,
                approvalMessageLogs: [
                  {
                    approvalLogId: 'approvalLog_456',
                    approvalOperator: {
                      userId: getUniqId(),
                      account: 'account1',
                      nickname: '审批人1',
                    },
                    approvalProcessId: 'approvalProcess_789',
                    approvalUserResourceId: ['user_003', 'user_004'],
                    approvalUserResourceType: EApprovalUserResourceType.USER,
                    realApprovalOperator: {
                      userId: getUniqId(),
                      account: 'account1',
                      nickname: '审批人1',
                    },
                    message: '同意此次审批',
                    approvalTime: 1621234567890,
                    approvalProcessStatus: EApprovalProcessStatus.PASS,
                  },
                  {
                    approvalLogId: 'approvalLog_456',
                    approvalOperator: {
                      userId: getUniqId(),
                      account: 'account1',
                      nickname: '审批人2',
                    },
                    approvalProcessId: 'approvalProcess_789',
                    approvalUserResourceId: ['user_003', 'user_004'],
                    approvalUserResourceType: EApprovalUserResourceType.USER,
                    realApprovalOperator: {
                      userId: getUniqId(),
                      account: 'account1',
                      nickname: '审批人2',
                    },
                    message: '同意此次审批',
                    approvalTime: 1621234567890,
                    approvalProcessStatus: EApprovalProcessStatus.PENDING,
                  },
                ],
              },
            ],
          },
        }),
      );
    },
  ),

  graphql.mutation('startApproval', (req, res, ctx) => {
    return res(
      ctx.data({
        startApproval: {
          isEnd: _.sample([true, false]),
        },
      }),
    );
  }),
];
