import { ReactComponent as BatchInputSvg } from '@/assets/icon/Batch-Input-light-line.svg';
import {
  ECommonFilterValueType,
  TFilterSelectValues,
} from '@/common/domain/filter/Filter/filter';
import t from '@/locales';
import { EColumnDataType, EDateGranularityType } from '@/typings';
import { Input, Progress } from '@aloudata/aloudata-design';
import Checkbox from '@aloudata/aloudata-design/dist/Checkbox';
import { ReactComponent as AddLLine } from '@/assets/icon/Sign-MAX-light-line.svg';
import { ReactComponent as SearchLine } from '@/assets/icon/Search-light-line.svg';
import cls from 'classnames';
import _ from 'lodash';
import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import styles from './index.less';
import Select from '@aloudata/aloudata-design/dist/Select';
import {
  ESpecialColumnValue,
  parseCustomValue,
  specialColumnValueMap,
} from './helper';
import MultipleInputModal from './MultipleInputModal';
import { useQueryDatasetFilterValue } from '@/services/queryColumns';
import {
  EFilterType,
  IColumnDep,
  IDimensionDep,
  ITableDep,
  IMetricDep,
} from '@/common/domain/filter/Filter/types';
import { IDataset } from '@/typings/dataset';
import { useQueryDimensionValues } from '@/services/dimension';
import { useQueryTableColumnValues } from '@/services/datasource';
import { useQueryMetricColumnValues } from '@/common/domain/filter/NewFilter/helper/useQueryMetricColumnValues';
export interface ISelectOptions {
  originOptions: {
    label: string;
    value: string;
    className?: string | undefined;
  }[];
  displayOptions: {
    label: string;
    value: string;
    className?: string | undefined;
  }[];
}
interface IProps {
  dependency: IDimensionDep | IColumnDep | ITableDep | IMetricDep;
  value: string[];
  onSubmit?: (data: TFilterSelectValues) => void;
  granularity?: EDateGranularityType;
  className?: string;
  dataType: EColumnDataType;
  showSearch?: boolean;
  size?: 'small' | 'middle' | 'large';
  disabled?: boolean;
  isSingleValue?: boolean;
}
export interface IResult {
  data: {
    label: string;
    value: string;
  }[];
  hasMore: boolean;
}
const FilterSelect = (props: IProps) => {
  const {
    // 字段id
    onSubmit,
    showSearch = true,
    size,
    disabled: propsDisabled,
    value,
    dataType = EColumnDataType.TEXT,
    dependency,
    granularity,
    isSingleValue,
  } = props;
  const {
    data: queryDatasetFilterValueRes,
    run: queryDatasetFilterValue,
    loading: queryDatasetFilterValueLoading,
  } = useQueryDatasetFilterValue();
  const {
    data: queryDimensionValueRes,
    run: queryDimensionValue,
    loading: queryDimensionValueLoading,
  } = useQueryDimensionValues();
  const {
    data: queryTableColumnValueRes,
    run: queryTableValue,
    loading: queryTableValueLoading,
  } = useQueryTableColumnValues();
  const {
    data: queryMetricValueRes,
    run: queryMetricValue,
    loading: queryMetricValueLoading,
  } = useQueryMetricColumnValues();

  const resDataMap = useMemo(() => {
    return {
      [EFilterType.FILTER]: queryDatasetFilterValueRes?.data,
      [EFilterType.DIMENSION_FILTER]: queryDimensionValueRes?.table,
      [EFilterType.TABLE_FILTER]: queryTableColumnValueRes?.table,
      [EFilterType.METRIC_FILTER]: queryMetricValueRes
        ? {
            [EFilterType.METRIC_FILTER]: queryMetricValueRes,
          }
        : undefined,
    };
  }, [
    queryDatasetFilterValueRes?.data,
    queryDimensionValueRes?.table,
    queryMetricValueRes,
    queryTableColumnValueRes?.table,
  ]);

  // 从服务端查询到的候选项
  const options = useMemo(() => {
    const resData = resDataMap[dependency.type];
    if (!resData) {
      return [];
    }
    if (resData) {
      // 因为服务端返回的是个 key-value结构，我们只需要 value，所以默认直接取得 value 值
      const datas = Object.values(resData)[0];
      if (datas.length > 0) {
        const vals: Array<{
          label: string;
          value: string;
        }> = [];
        datas.forEach((data) => {
          // 服务端返回的值是个数组，第一项是 value，
          // 第二位表示跨的行数，是电子表格里分类汇总用到的，
          // 第三位表示是不是有多值。其他的我们这里都用不到的，直接取第一位就行了。
          const valueData = data[0];
          // 需要排除掉 null 值
          if (!_.isNull(valueData)) {
            const val = String(valueData);
            // 如果是日期、数组格式化过的，需要同步处理下显示的内容
            const label = val;
            vals.push({
              label,
              value: val,
            });
          }
        });
        return vals;
      }
    }
    return [];
  }, [resDataMap, dependency.type]);

  // 选中的值
  const [values, setValues] = useState([...value]);
  // 只看选中值
  const [isShowSelected, setIsShowSelected] = useState(false);
  // 搜索的关键词
  const [searchValue, setSearchValue] = useState('');
  // 该列的格式化配置
  const onSubmitSelectValue = useCallback(
    (selectedValues: string[]) => {
      const data: TFilterSelectValues = [];
      selectedValues.forEach((val) => {
        if (val === specialColumnValueMap[ESpecialColumnValue.NULL].value) {
          data.push({
            type: ECommonFilterValueType.NULL,
          });
        } else if (
          val === specialColumnValueMap[ESpecialColumnValue.EMPTY].value
        ) {
          data.push({
            type: ECommonFilterValueType.EMPTY,
          });
        } else {
          if (
            dataType === EColumnDataType.INT ||
            dataType === EColumnDataType.DECIMAL ||
            dataType === EColumnDataType.DOUBLE
          ) {
            data.push({
              type: ECommonFilterValueType.NUMBER,
              value: val,
            });
          } else {
            data.push({
              type: ECommonFilterValueType.STRING,
              value: val,
            });
          }
        }
      });
      onSubmit?.(data);
    },
    [onSubmit, dataType],
  );
  const onChange = useCallback(
    (newValues: string[]) => {
      setValues(newValues);
      onSubmitSelectValue(newValues);
    },
    [onSubmitSelectValue],
  );
  // 搜索
  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
    e.stopPropagation();
    e.preventDefault();
  };
  // 切换只展示已选
  const keepOnlySelectedValues = useCallback(() => {
    if (values.length === 0) return;
    setIsShowSelected(true);
    setSearchValue('');
  }, [values.length]);
  // 自定义添加搜索值
  const addSelectOption = useCallback(
    (addValue: string) => {
      const newValue = parseCustomValue(addValue, dataType);
      if (newValue === null) {
        // 不合法的自定义输入值，不生效
        setSearchValue('');
        return;
      }
      const newValues = _.uniq([...values, addValue]);
      setValues(newValues);
      onSubmitSelectValue(newValues);
    },
    [values, onSubmitSelectValue, dataType],
  );
  // 展示全部
  const showAllOptions = useCallback(() => {
    setIsShowSelected(false);
    setSearchValue('');
  }, [setIsShowSelected]);
  // 所有候选项
  const allOptions = useMemo(() => {
    const res: IResult['data'] = [];
    // 放入『NULL』
    res.push(specialColumnValueMap[ESpecialColumnValue.NULL]);
    if (dataType === EColumnDataType.TEXT) {
      res.push(specialColumnValueMap[ESpecialColumnValue.EMPTY]);
    }
    // 不存在于候选项中的，自定义输入的值
    const customValues = _.difference(values, _.map(options, 'value'));
    _.forEach(customValues, (valItem) => {
      if (valItem === '') {
        return;
      }
      if (valItem === specialColumnValueMap[ESpecialColumnValue.NULL].value) {
        // 删除 null 对应的值
        return;
      }
      if (valItem === specialColumnValueMap[ESpecialColumnValue.EMPTY].value) {
        // 删除 empty 对应的值
        return;
      }
      res.push({
        label: valItem.toString(),
        value: valItem.toString(),
      });
    });
    // 放入服务端查询回来的候选项
    _.forEach(options, (optionItem) => {
      if (optionItem.value === '') {
        return;
      }
      res.push(optionItem);
    });
    return res;
  }, [dataType, options, values]);
  // 当前可见的候选项
  const displayOptions = useMemo(() => {
    let res = allOptions;
    if (isShowSelected) {
      // 仅显示选中的值
      res = _.filter(res, (item) => {
        return values.includes(item.value);
      });
    }
    // 考虑有搜索关键词的情况
    if (searchValue) {
      // 命中搜索关键词的情况
      res = _.filter(res, (item) => {
        return item.label.toLowerCase().includes(searchValue.toLowerCase());
      });
    }
    return res;
  }, [allOptions, searchValue, isShowSelected, values]);
  // 所有候选项都被选中
  const isAllOptionChecked = useMemo(
    () =>
      _.isEqual(
        values,
        _.map(allOptions, (optionItem) => optionItem.value),
      ),
    [values, allOptions],
  );
  const onChangeAllCheckbox = useCallback(() => {
    const checked = !isAllOptionChecked;
    let newValues: string[] = [];
    if (checked) {
      newValues = _.map(allOptions, (optionItem) => optionItem.value);
    }
    setValues(newValues);
    onSubmitSelectValue(newValues);
  }, [allOptions, onSubmitSelectValue, isAllOptionChecked]);

  const [isMultipleInputModalVisible, setIsMultipleInputModalVisible] =
    useState(false);
  const onChangeByMultipleInput = useCallback(
    (newValues: string[]) => {
      setValues(newValues);
      onSubmitSelectValue(newValues);
    },
    [onSubmitSelectValue],
  );
  const dropdownRender = (menu: React.ReactElement) => {
    let tip = '';
    if (displayOptions.length === 0 && searchValue) {
      tip = t.filter.filter.addValueManually;
    } else if (!isShowSelected) {
      tip = t.filter.filter.selectedOptionMaxLength;
    }
    return (
      <div className={styles.dropdownRenderWrapper}>
        <div className={styles.loadingBar}>
          <Progress
            loading={
              queryDatasetFilterValueLoading ||
              queryDimensionValueLoading ||
              queryTableValueLoading ||
              queryMetricValueLoading
            }
          />
        </div>
        <div
          className={cls(styles.inputWrapper, {
            [styles.showSearchInput]: showSearch,
          })}
        >
          <Input
            className={styles.inputSearch}
            onChange={onInputChange}
            value={searchValue}
            placeholder={t.filter.filter.search}
            onKeyDown={(e) => {
              // 不加这行的话，退格键会冒泡到 Select 组件上，触发 onChange 删除最后一个 values
              e.stopPropagation();
            }}
            prefix={<SearchLine size={14} color={styles.NL40} />}
            size={size}
            allowClear
          />
        </div>

        {!isShowSelected && !searchValue && (
          <div className={styles.checkboxWrapper} onClick={onChangeAllCheckbox}>
            {/* // 点击 checkbox 会导致浏览器崩溃，由于 checkbox 是简单封装了 antd 的 checkbox 组件，没能查到深层原因，因此在 checkbox 上加了一个遮罩层防止点到 checkbox */}
            <div className={styles.checkboxLabel}></div>
            <Checkbox checked={isAllOptionChecked} className={styles.allLabel}>
              {t.filter.filter.allOptions}
            </Checkbox>
          </div>
        )}
        <div className={styles.contentWrapper}>{menu}</div>
        {displayOptions.length === 0 && searchValue && (
          <>
            <div
              className={cls(styles.addBtn, styles.showAddBtn)}
              onClick={() => {
                addSelectOption(searchValue);
              }}
            >
              <AddLLine />
              <span className={styles.searchValue}>{`“${searchValue}”`}</span>
            </div>
          </>
        )}
        {tip && <div className={styles.optionsTip}>{tip}</div>}
        <div className={styles.footer}>
          {isShowSelected ? (
            <div
              className={cls(styles.showOptionsValue, styles.isShowAllValue)}
              onClick={showAllOptions}
            >
              {t.filter.filter.showAllValue}
            </div>
          ) : (
            <div
              className={cls(
                styles.showOptionsValue,
                styles.isShowSelectValue,
                {
                  [styles.selectedValueDisabled]: values.length === 0,
                },
              )}
              onClick={keepOnlySelectedValues}
            >
              {t.filter.filter.selectedValue({
                count: values.length,
              })}
            </div>
          )}
          <div
            className={styles.multiInputBtn}
            onClick={() => {
              setIsMultipleInputModalVisible(true);
            }}
          >
            <BatchInputSvg className={styles.multiInputIcon} color="#3769AD" />
            <span>{t.filter.filter.multipleInput.default}</span>
          </div>
        </div>
      </div>
    );
  };

  const disabled = useMemo(() => {
    const hasDependence =
      (dependency.type === EFilterType.DIMENSION_FILTER && !!dependency.name) ||
      (dependency.type === EFilterType.FILTER && !!dependency.dataset) ||
      (dependency.type === EFilterType.TABLE_FILTER &&
        !!dependency.tableGuid) ||
      (dependency.type === EFilterType.METRIC_FILTER && !!dependency.code);

    return !hasDependence || propsDisabled;
  }, [dependency, propsDisabled]);

  if (isSingleValue) {
    const singleValue = values?.[0] ?? '';
    return (
      <Input
        onChange={(e) => {
          const val = e.target.value;
          onChange([val]);
        }}
        value={singleValue}
      />
    );
  }

  return (
    <div className={styles.wrap}>
      <Select
        allowClear={true}
        size={size}
        placeholder={t.filter.filter.selectField}
        mode="multiple"
        showArrow={true}
        onChange={onChange}
        value={values}
        listHeight={150}
        disabled={disabled}
        className={cls(styles.select, props.className)}
        popupClassName={styles.drop}
        onDropdownVisibleChange={async (open) => {
          if (
            open &&
            (!queryDatasetFilterValueRes ||
              !queryDimensionValueRes ||
              !queryTableColumnValueRes ||
              !queryMetricValueRes)
          ) {
            if (dependency.type === EFilterType.FILTER) {
              queryDatasetFilterValue(
                dependency.dataset as unknown as IDataset,
                dependency.columnName,
                granularity,
              );
            }
            if (dependency.type === EFilterType.DIMENSION_FILTER) {
              queryDimensionValue({
                name: dependency.name,
                granularity,
              });
            }
            if (dependency.type === EFilterType.TABLE_FILTER) {
              queryTableValue({
                tableGuid: dependency.tableGuid,
                columnName: dependency.columnName,
              });
            }
            if (dependency.type === EFilterType.METRIC_FILTER) {
              queryMetricValue({
                metricCode: dependency.code,
                dimensionName: dependency.dimensionName,
                granularity,
              });
            }
          }
          if (!open) {
            return;
          }
          setSearchValue('');
        }}
        tagRender={(tagProps) => {
          const { label, value: tagValue } = tagProps;
          const isLastValue = values[values.length - 1] === tagValue;
          return (
            <span
              className={cls({
                [styles.nullLabel]:
                  tagValue ===
                    specialColumnValueMap[ESpecialColumnValue.NULL].value ||
                  tagValue ===
                    specialColumnValueMap[ESpecialColumnValue.EMPTY].value,
              })}
            >
              {isLastValue ? label : `${label}，`}
            </span>
          );
        }}
        dropdownRender={dropdownRender}
        onInputKeyDown={(e) => {
          // 禁止键盘事件
          e.stopPropagation();
          e.preventDefault();
        }}
        options={displayOptions}
      ></Select>
      <MultipleInputModal
        open={isMultipleInputModalVisible}
        onClose={() => {
          setIsMultipleInputModalVisible(false);
        }}
        onChange={onChangeByMultipleInput}
        dataType={dataType}
        values={values}
      />
    </div>
  );
};
export default FilterSelect;
