import t from '@/locales';
import { Empty, HighlightText, Tree } from '@aloudata/aloudata-design';
import { PColumn } from './index';
import styles from './index.less';
import { ReactComponent as DatasetIcon } from '@/assets/icon/Dataset-light-line.svg';
import ColumnDataTypeIcon from '../../../ui/ColumnDataTypeIcon';
import { fuzzyMatch } from '@/pages/Dataset/helpers';
import classNames from 'classnames';
import { EColumnDataType, EDateGranularityType } from '@/typings';
import { isDateLikeDataType } from '@/common/utils';
import { originDataType2DataTypeMap } from '@/common/domain/Formula/constant';
import _ from 'lodash';
import dateTimeMap from '@/constants/dateTime';
import { KEEP_FOCUS_CLASS_COlUMN } from '@/common/domain/codeEditor/CodeEditor/PromptTrigger';
interface IItemProps {
  sheetId: string;
  columns: PColumn[];
  sheetName: string;
}
interface IMultipleColumnListProps {
  items: IItemProps[];
  onItemClick: (
    selectedColumnInfo: PColumn,
    sheetId: string,
    colId: string,
    granularity?: EDateGranularityType,
  ) => void;
  searchKey: string;
  // 是否展示时间粒度
  supportGranularity?: boolean;
  // 是否展示时/分/秒 等时间粒度
  supportTimeGranularity?: boolean;
  checkedKeys?: ICheckedKey[];
  onCheck?: (checkedKeys: ICheckedKey[]) => void;
  unremovable?: ICheckedKey[];
}

export interface ICheckedKey {
  sheetId: string;
  colId: string;
}

const ICON_SIZE = 16;
const MultipleColumnList = (props: IMultipleColumnListProps) => {
  const {
    items,
    onItemClick,
    searchKey,
    supportGranularity,
    checkedKeys,
    onCheck,
    unremovable,
    supportTimeGranularity = true,
  } = props;
  const treeData = items.map((item) => ({
    title: item.sheetName,
    displayName: item.sheetName,
    key: item.sheetId,
    sheetId: item.sheetId,
    icon: <DatasetIcon size={ICON_SIZE} className={styles.icon} />,
    children: item.columns.map((column) => {
      const columnDataType = originDataType2DataTypeMap[column.originDataType];
      const isDateLike = isDateLikeDataType(columnDataType);
      const showGranularity = supportGranularity && isDateLike;
      // 将时间类型的维度按照粒度扩充
      const dateGranularityList = [
        EDateGranularityType.YEAR,
        EDateGranularityType.QUARTER,
        EDateGranularityType.MONTH,
        EDateGranularityType.WEEK,
        EDateGranularityType.DAY,
      ];
      const granularityMap: {
        [key in
          | EColumnDataType.DATE
          | EColumnDataType.DATE_TIME]: EDateGranularityType[];
      } = {
        [EColumnDataType.DATE]: dateGranularityList,
        [EColumnDataType.DATE_TIME]: supportTimeGranularity
          ? [
              ...dateGranularityList,
              EDateGranularityType.HOUR,
              EDateGranularityType.MINUTE,
              EDateGranularityType.SECOND,
            ]
          : dateGranularityList,
      };

      // 日期类型的字段，支持时间粒度选择
      const listWithGranularity =
        supportGranularity && isDateLike
          ? _.map(
              granularityMap[
                columnDataType as
                  | EColumnDataType.DATE
                  | EColumnDataType.DATE_TIME
              ],
              (granularity) => ({
                title: dateTimeMap[granularity].name,
                displayName: column.displayName,
                key: encodeKey(item.sheetId, column.id, granularity),
                sheetId: item.sheetId,
                icon: (
                  <ColumnDataTypeIcon
                    size={ICON_SIZE}
                    dataType={column.dataType}
                    className={styles.icon}
                    granularity={granularity}
                  />
                ),
                columnInfo: column,
                granularity,
              }),
            )
          : undefined;

      const key = encodeKey(item.sheetId, column.id);

      return {
        title: column.displayName,
        displayName: column.displayName,
        key,
        sheetId: item.sheetId,
        icon: (
          <ColumnDataTypeIcon
            size={ICON_SIZE}
            dataType={column.dataType}
            className={styles.icon}
          />
        ),
        columnInfo: column,
        // 开启了时间粒度选择后，时间类型的字段不可选
        selectable: !showGranularity,
        children: listWithGranularity,
        disabled: unremovable
          ?.map((itemData) => encodeKey(itemData.sheetId, itemData.colId))
          .includes(key),
      };
    }),
  }));

  const checkable = typeof onCheck === 'function';
  return (
    <div className={styles.contentWrapper}>
      {items.length !== 0 ? (
        <Tree.DirectoryTree<{
          title: string;
          displayName: string;
          key: string;
          sheetId: string;
          icon: React.ReactNode;
          columnInfo?: PColumn;
          granularity?: EDateGranularityType;
          children?: unknown[];
        }>
          checkable={checkable}
          onCheck={(newCheckedKeyStrList, e) => {
            const { checkedNodes } = e;
            const leafNodes = checkedNodes.filter((node) => !node.children);
            const newCheckedKeys = leafNodes.map((node) => ({
              sheetId: node.sheetId,
              colId: node.columnInfo?.id || '',
            }));
            onCheck?.(newCheckedKeys);
          }}
          checkedKeys={checkedKeys?.map((key) =>
            encodeKey(key.sheetId, key.colId),
          )}
          size="small"
          treeData={treeData}
          showIcon={false}
          titleRender={(node) => {
            return (
              <div
                className={classNames(styles.title, KEEP_FOCUS_CLASS_COlUMN)}
              >
                <div className={styles.icon}>{node.icon}</div>
                <div className={styles.content}>
                  <div className={styles.text} title={node.title}>
                    <HighlightText keyword={searchKey}>
                      {node.title}
                    </HighlightText>
                  </div>
                  {node.columnInfo?.name && !node.granularity && (
                    <div
                      className={styles.description}
                      title={node.columnInfo?.name}
                    >
                      <HighlightText keyword={searchKey}>
                        {node.columnInfo.name}
                      </HighlightText>
                    </div>
                  )}
                </div>
              </div>
            );
          }}
          filterTreeNode={
            searchKey
              ? (node) => {
                  const displayName = _.get(node, 'displayName', '');
                  const name = _.get(node, 'columnInfo.name', '');
                  return (
                    fuzzyMatch(displayName, searchKey) ||
                    fuzzyMatch(name, searchKey)
                  );
                }
              : undefined
          }
          onSelect={(key, e) => {
            if ('columnInfo' in e.node) {
              onItemClick(
                e.node.columnInfo as PColumn,
                e.node.sheetId,
                key[0] as string,
                e.node.granularity,
              );
            }
          }}
        />
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SEARCH_SMALL}
          size="small"
          title={t.filter.sidebar.noDataTip}
          className={styles.empty}
        />
      )}
    </div>
  );
};
export default MultipleColumnList;

function encodeKey(
  sheetId: string,
  columnId: string,
  granularity: EDateGranularityType | '' = '',
) {
  return `${sheetId}_${columnId}_${granularity}`;
}
