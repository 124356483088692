// src/pages/Dataset/Detail/index.tsx
import { EResourceType } from "@/typings/authority";
import PreQueryDetail, {
  postPreQuery
} from "@/common/domain/resource/PreQueryDetail";
async function clientLoader() {
  const res = await postPreQuery(EResourceType.DATASET);
  return res;
}

// src/pages/Metric/Detail/index.tsx
import { EResourceType as EResourceType2 } from "@/typings/authority";
import PreQueryDetail2, {
  postPreQuery as postPreQuery2
} from "@/common/domain/resource/PreQueryDetail";
async function clientLoader2() {
  const res = await postPreQuery2(EResourceType2.METRIC);
  return res;
}

// src/pages/AnalysisView/Detail/index.tsx
import { EResourceType as EResourceType3 } from "@/typings/authority";
import PreQueryDetail3, {
  postPreQuery as postPreQuery3
} from "@/common/domain/resource/PreQueryDetail";
async function clientLoader3() {
  const res = await postPreQuery3(EResourceType3.ANALYSIS_VIEW);
  return res;
}

// src/pages/AnalysisView/Define/index.tsx
import { isCreateMode } from "/root/workspace/any-metrics_wxFJ/src/pages/AnalysisView/Define/common/hooks";
import { EResourceType as EResourceType4 } from "@/typings/authority";
import PreQueryDetail4, {
  postPreQuery as postPreQuery4
} from "@/common/domain/resource/PreQueryDetail";
async function clientLoader4() {
  if (isCreateMode()) {
    return Promise.resolve({
      isExist: true,
      hasAuthority: true
    });
  }
  const res = await postPreQuery4(EResourceType4.ANALYSIS_VIEW);
  return res;
}

// src/.umi-production/core/loaders.ts
var loaders_default = {
  "9": clientLoader,
  "15": clientLoader2,
  "18": clientLoader3,
  "20": clientLoader4,
  "21": clientLoader4,
  "22": clientLoader4
};
export {
  loaders_default as default
};
